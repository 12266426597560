import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import style from "./JobSearch.module.css";
import { Link, useNavigate, useLocation } from "react-router-dom";

import JobSearch from "./JobSearch";

import accounting from "../img/accounting.png";
import agriculture from "../img/agriculture1.png";
import architecture from "../img/architecture1.png";
import automotive from "../img/automotive.png";
import banking from "../img/bank.png";
import beauty from "../img/beauty.png";
import business from "../img/business.png";
import construction from "../img/construction.png";
import customerService from "../img/customer.png";
import education from "../img/education.png";
import engineering from "../img/engineering.png";
import finance from "../img/finance.png";
import healthcare from "../img/healthcare.png";
import hospitality from "../img/hotel.png";
import humanResources from "../img/human.png";
import informationTechnology from "../img/technology.png";
import insurance from "../img/insurance.png";
import legal from "../img/legal.png";
import logistics from "../img/logistics.png";
import manufacturing from "../img/factory.png";
import marketing from "../img/marketing.png";
import media from "../img/media.png";
import nonprofit from "../img/handshake.png";
import realEstate from "../img/realestate.png";
import retail from "../img/retail.png";
import sales from "../img/sales.png";
import science from "../img/science.png";
import security from "../img/security.png";
import socialServices from "../img/social.png";
import telecommunications from "../img/telephone.png";
import transportation from "../img/bus.png";
import travel from "../img/travel.png";
import other from "../img/other.png";
import test from "../img/test.png";







function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function JobCategory() {

  const navigate = useNavigate();

  


  let categories = [
    {
      id: "Accounting",
      category: "Accounting",
      image:  accounting
    },
    // {
    //   id: "Agriculture",
    //   category: "Agriculture",
    //   image:  agriculture
    // },
    {
      id: "Architecture",
      category: "Architecture",
      image:  architecture
    },
    // {
    //   id: "Automotive",
    //   category: "Automotive",
    //   image:  automotive
    // },
    // {
    //   id: "Banking",
    //   category: "Banking",
    //   image:  banking
    // },
    // {
    //   id: "Beauty",
    //   category: "Beauty",
    //   image:  beauty
    // },
    // {
    //   id: "Business",
    //   category: "Business",
    //   image:  business
    // },
    {
      id: "Construction",
      category: "Construction",
      image:  construction
    },
    {
      id: "Customer_Service",
      category: "Customer Service",
      image:  customerService
    },
    {
      id: "Education",
      category: "Education",
      image:  education
    },
    {
      id: "Engineering",
      category: "Engineering",
      image:  engineering
    },
    {
      id: "Finance",
      category: "Finance",
      image:  finance
    },
    // {
    //   id: "Healthcare",
    //   category: "Healthcare",
    //   image:  healthcare
    // },
    // {
    //   id: "Hospitality",
    //   category: "Hospitality",
    //   image:  hospitality
    // },
    {
      id: "Human_Resources",
      category: "Human Resources",
      image:  humanResources
    },
    {
      id: "Information_Technology",
      category: "Information Technology",
      image:  informationTechnology
    },
    // {
    //   id: "Insurance",
    //   category: "Insurance",
    //   image:  insurance
    // },
    // {
    //   id: "Legal",
    //   category: "Legal",
    //   image:  legal
    // },
    {
      id: "Logistics",
      category: "Logistics",
      image: logistics
    },
    // {
    //   id: "Manufacturing",
    //   category: "Manufacturing",
    //   image: manufacturing
    // },
    {
      id: "Marketing",
      category: "Marketing",
      image: marketing
    },
    // {
    //   id: "Media",
    //   category: "Media",
    //   image:  media
    // },
    // {
    //   id: "Nonprofit",
    //   category: "Nonprofit",
    //   image:  nonprofit
    // },
    // {
    //   id: "Real_Estate",
    //   category: "Real Estate",
    //   image: realEstate
    // },
    {
      id: "Retail_And_Sales",
      category: "Retail and Sales",
      image:  sales
    },
    // {
    //   id: "Sales",
    //   category: "Sales",
    //   image:  sales
    // },
    // {
    //   id: "Science",
    //   category: "Science",
    //   image:  test
    // },
    // {
    //   id: "Security",
    //   category: "Security",
    //   image:  security
    // },
    {
      id: "Social_Services",
      category: "Social Services",
      image:  socialServices
    },
    // {
    //   id: "Telecommunications",
    //   category: "Telecommunications",
    //   image:  telecommunications
    // },
    // {
    //   id: "Transportation",
    //   category: "Transportation",
    //   image:  transportation
    // },
    // {
    //   id: "Travel",
    //   category: "Travel",
    //   image:  travel
    // },
    // {
    //   id: "Other",
    //   category: "Other",
    //   image:  other
    // },

  ];
  return (
    <div className={style.bigCategory}>
      <JobSearch/>
        <div className={style.categoriesTitle}> Browse categories</div>
    <div className={style.categoryContainer}>
      {categories.map((category) => (
        
        <Link to={`/jobSearch/${category.id}`} key={category.id} style={{ textDecoration: 'none' }}>
        <Card sx={{ 
          maxWidth: 345, 
          // boxShadow:'none', 
          // border:1, 
          // borderColor: "grey.400" 
          }} 
        className={style.categoryCard}
          >
        {/* <Card onClick={() => navigate(`/jobSearch/cat=${category.id}`)} sx={{ maxWidth: 345 }} className={style.categoryCard}> */}
        <CardActionArea>
          <CardMedia
            component="img"
            height="198"
            image={category.image}
            alt=""
            sx={{p:6, bgcolor: "#e8eaf6"}}
          />
          <CardContent className={style.cardContent}>
            <Typography className={style.cardContentText} gutterBottom sx={{ fontSize: 15, fontFamily:"Exo" }} component="div">
                {category.category}
            </Typography>
          </CardContent>
        </CardActionArea>
       
        
      </Card>
       </Link>
      ))}  
    </div>
      
      
    </div>
  );
}
