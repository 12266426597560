import { useLocation, useParams, useSearchParams } from "react-router-dom";
import style from "./JobSearch.module.css";
import { GetJobListByCategory } from "../hooks/jobAPI";
import { useEffect, useState } from "react";
import JobListItem, { JobListProps } from "./JobListItem";
import { Pagination, Skeleton } from "@mui/material";
import { tidyUpJobAdDescription } from "../hooks/aiApi";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import JobSearch from "./JobSearch";
import Chip from '@mui/material/Chip';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function JobResult() {

  ScrollToTop()
  
  // Get Job List
  const [jobList, setJobList] = useState<JobListProps[]>([]);
  const [jobDetails, setJobDetails] = useState<{
    company: string;
    title: string;
    requirement: string;
    link:string;
  }>();
  const { category } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const [flag, setFlag] = useState(false);

  const [ loadingFetch , setLoadingFetch ] = useState(false)

  // const [ newJobAdDescription , setNewJobAdDescription ] = useState<{suggestion:string}>({suggestion:""})

  useEffect(() => {
    // GetJobListByCategory(category!,(res:any) => setJobList(res))
    const getJobList = async () => {
      try {
        setLoadingFetch(true)
        const response = await GetJobListByCategory(category!);
        setLoadingFetch(false)
        const jobListContent = response.jobList;
        console.log("jobListContent: ", jobListContent)
        setJobList(jobListContent);
        // setPage({...page, count: jobList.length})
        setFlag(true);
      } catch (error) {
        console.log("Error message: ", error);
      }
    };
    getJobList();
  }, [category]);

  useEffect(() => {
    const fetchData = async () => {
      if (flag) {
        setJobDetails(findJobInfo())
      };
      // let revisedJobAdDescription = await tidyUpJobAdDescription(jobDetails?.requirement!)
      // setNewJobAdDescription(revisedJobAdDescription)
    }
    fetchData()
  }, [searchParams, flag]);

  const getJobDetail = (id: number) => {
    const params = { jobId: id.toString() };
    setSearchParams(params);
  };

  let jobIdNumber = searchParams.get("jobId");
  const findJobInfo = () => {
    
    const IndexValue = jobList.findIndex((obj) => obj.id === +jobIdNumber!);
    console.log("IndexValue: ", IndexValue);

    return jobList[IndexValue];
  };



const [page, setPage] = useState(1);
const itemsPerPage = 10; // Number of items to display per page
const totalItems = jobList.length; // Total number of jobs
const totalPages = Math.ceil(totalItems / itemsPerPage); // Calculate the total number of pages

const handlePageChange = (event:any, value:any) => {
    setPage(value);
    

  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const pageJobs = jobList.slice(startIndex, endIndex);

   useEffect(()=>{
      window.scrollTo({top: 0});
   },[startIndex])

  return (
    <div className={style.jobByCategoryBigContainer}>
            <JobSearch/>
    <div className={style.jobResultBigContainer}>
      <div className={style.jobResultContainer}>
        
        <div className={style.jobListArea}> 
            <div>Search results: <b>{jobList.length} {jobList.length>1? 'jobs': 'job'}</b> in <b>{category?.split("_").join(" ")}</b></div>

            { loadingFetch ? <Skeleton variant="rectangular" width={400} height={170} sx={{borderRadius:"10px"}} /> : 
            <>
            {Array.isArray(jobList) && jobList.length > 0
              ? pageJobs.map((job: JobListProps) => (
                  <JobListItem
                    paramsJobId={jobIdNumber}
                    id={job.id}
                    key={job.id}
                    category={job.category}
                    title={job.title}
                    requirement={job.requirement}
                    company={job.company}
                    getJobDetail={() => getJobDetail(job.id)}
                    link={job.link}
                  />
                ))
              : 
              ""
              
              }
            </>
            }

              {page ?
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
                : ""
              }

        </div>

        <div className={style.choseJobArea}>
          {/* {searchParams.get("jobId") && newJobAdDescription.suggestion ? ( */}
          {searchParams.get("jobId") ? (

            <div className={style.jobDetailArea}>
              <Link href={jobDetails?.link} underline="hover" sx={{color:"black"}}>
                <Typography
                    component="h1"
                    align="left"
                    sx={{ fontFamily:'Exo', fontWeight: 'bold', fontSize: 32}}
                >
                    {jobDetails?.title}
                </Typography>
              </Link>
              <Typography
                  component="h1"
                  variant="subtitle1"
                  align="left"
                  color="text.secondary"
                  sx={{ fontFamily:'Exo', whiteSpace: 'pre-line'}}
              >
                  {jobDetails?.company}
              </Typography>
              <Button sx={{mt:2, textTransform:"none"}} variant="contained" href={jobDetails?.link}>Apply in JobsDB</Button>
              <Typography sx={{mt:5, whiteSpace: 'pre-line'}}>{jobDetails?.requirement}</Typography>
              {/* <p>Revised Job Requirement: <br></br>{newJobAdDescription.suggestion}</p> */}
            </div>
          ) : (
           
            <Typography
                component="h1"
                variant="subtitle1"
                align="left"
                sx={{ fontFamily:'Exo'}}
            >
                {!loadingFetch ? "Choose job on left" : ""}
            </Typography>
          )}
        </div>
      </div>
    </div>
    </div>
  );
}
