import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import EmailIcon from "@mui/icons-material/Email";
import ArticleIcon from "@mui/icons-material/Article";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Divider from "@mui/material/Divider";
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import Collapse from '@mui/material/Collapse';
import { TransitionGroup } from 'react-transition-group';
import Fade from '@mui/material/Fade';
import Grow from '@mui/material/Grow';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';

import style from "./Dashboard.module.css";
import { Button, Link, Pagination, Stack, Theme, createStyles, makeStyles } from "@mui/material";

import PostAddIcon from "@mui/icons-material/PostAdd";
import {
  deleteResume,
  useLoadCoverLetter,
  useLoadResume,
} from "../hooks/dashboardAPI";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { jwtDecode } from "jwt-decode";
import { Padding } from "@mui/icons-material";

import { useNavigate, useLocation } from "react-router-dom";
import ResumeCard from "./ResumeCard";
import CoverLetterCard from "./coverLetterCard";

type ResumeListType = { 
  resume_id: number; resume_title: string; s3_file_path: string
}

const actions:Array<any> = [
  { icon: <ArticleIcon />, name: "Create Resume", navigate: "/cvBuilder/create"},
  { icon: <EmailIcon />, name: "Create Cover Letter" , navigate: "/coverLetterBuilder/create"}, ,
];

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={style.tabContent}
    >
      {value === index && (
        <Box sx={{ pt: 3, width: "100%", justifyContent: "space-around" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Dashboard() {

  const [value, setValue] = useState(0);
  const [resumeOpen, setResumeOpen] = useState(true);
  const [coverLetterOpen, setCoverLetterOpen] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if(newValue === 0){
      setResumeOpen(true);
      setCoverLetterOpen(false);
    }
    else if(newValue === 1){
      setResumeOpen(false);
      setCoverLetterOpen(true);
    }
  };

  const isAuthenticated = useSelector<RootState>(
    (state) => state.auth.isAuthenticated
  );

  let user = "";
  let user_id: number | null = null;
  if (isAuthenticated) {
    let decoded: { user_id: number; username: string } = jwtDecode(
      localStorage.getItem("token")!
    );
    // console.log("decoded", decoded);
    user = decoded.username;
    user_id = decoded.user_id;
  }

  const resumeList:
    | ResumeListType[]
    | string = useLoadResume(user_id);
  console.log("resumeList ", resumeList);

  const coverLetterList = useLoadCoverLetter(user_id);
  console.log("coverLetterList ", coverLetterList);


  // Pagination
  const [resumePage, setResumePage] = useState(1);
  const [ totalResumePages, setTotalResumePage ] = useState(0)
  const [ pageOfResume, setPageOfResume ] = useState<ResumeListType[]>([])
  const handleResumePageChange = (event:any, value:any) => {setResumePage(value)}
  useEffect(()=>{
    const itemsPerPage = 10
    const totalItems = resumeList?.length
    setTotalResumePage(Math.ceil(totalItems! / itemsPerPage))
    
    const startIndex = (resumePage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    if (resumeList && Array.isArray(resumeList)){
      setPageOfResume(resumeList.slice(startIndex, endIndex))
    }
    window.scrollTo({top: 0});
 },[resumeList,resumePage])

 const [ coverLetterPage, setCoverLetterPage ] = useState(1);
 const [ totalCoverLetterPages, setTotalCoverLetterPage ] = useState(0)
 const [ pageOfCoverLetter, setPageofCoverLetter ] = useState<any[]>([])
 const handleCoverLetterPageChange = (event:any, value:any) => {setCoverLetterPage(value)}
 useEffect(()=>{
   const itemsPerPage = 10
   const totalItems = coverLetterList?.length
   setTotalCoverLetterPage(Math.ceil(totalItems! / itemsPerPage))
   
   const startIndex = (coverLetterPage - 1) * itemsPerPage;
   const endIndex = startIndex + itemsPerPage;
   if (coverLetterList && Array.isArray(coverLetterList)){
    setPageofCoverLetter(coverLetterList.slice(startIndex, endIndex))
   }
   window.scrollTo({top: 0});
},[coverLetterList,coverLetterPage])



  return (
<>

{isAuthenticated ? (

  <>
      {Array.isArray(resumeList) && Array.isArray(coverLetterList)? 
          <div className={style.dashboardContainer}>
           
            <Box sx={{ width:"100%", maxWidth: '1040px'}}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="dashboard tabs"
                >
                  <Tab
                    sx={{ textTransform: "none", fontFamily: "Exo, sans-serif", }}
                    label="Resumes"
                    {...a11yProps(0)}
                  />
                  <Tab
                    sx={{ textTransform: "none" ,fontFamily: "Exo, sans-serif", }}
                    label="Cover Letters"
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>



              <CustomTabPanel value={value} index={0}>
                {/* <div className={style.createButtonContainer}>
                  <Button
                    startIcon={<PostAddIcon />}
                    variant="contained"
                    sx={{ 
                      textTransform: "none",
                      // fontFamily: "Exo, sans-serif",
                      // alignItems: "center" 
                    }}
                    onClick={() => navigate(`/cvBuilder/create`)}
                  >
                    Create Resume
                  </Button>
                </div> */}

              
                <div className={style.resumeCardContainer}>
                  
                  {Array.isArray(resumeList) && resumeList.length > 0 ? (
                   
                   
                    pageOfResume.map((resume) => (
                      
                      <ResumeCard
                        resumeOpen={resumeOpen}
                        resumeId={resume.resume_id}
                        resumeTitle={resume.resume_title}
                        s3FilePath={resume.s3_file_path}
                        key={`resumeId${resume.resume_id}`}
                      />

                    
                      
                    ))
                  
                  ) : (

                    //==================================resume card for create new==========================//
                    <>
                      {Array.isArray(resumeList) && resumeList.length === 0
                        ? 
                        <>
                        
                        <Grow in={resumeOpen}>
                        <Card
                        className={style.resumeCard}
                        sx={{ display: "flex", flex: "wrap", p: 3}}
                      >
                        <CardContent
                            sx={{
                              flex: "1 0 auto",
                              flexDirection: "column",
                              pl: 1,
                              mr: 2,
                              height: 276.48,
                              width: 200,
                          pt: 12,
                            }}
                          >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  verticalAlign: "middle",
                                }}
                              >
                                <IconButton 
                                  color="primary" 
                                  aria-label="add first resume"
                                  onClick={() => navigate(`/cvBuilder/create`)}
                                >
                                  <AddIcon sx={{fontSize: 50, height:50, width: 50}}/>
                                </IconButton>
                              </Box>
                            
                          </CardContent>

                        <Divider className={style.verticalDivider} orientation="vertical" variant="middle" sx={{bgcolor:'grey.700'}} flexItem />
                
                        <CardContent
                            sx={{
                              flex: "1 0 auto",
                              flexDirection: "column",
                              justifyContent: "center",
                              // textAlign: "center",
                            // p:3,
                            pr:1,
                            pt:1,
                              mb: 1,
                              ml:2,
                              // height: 80,
                              width: 220,
                            }}
                          >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "column",
                                  width:200,
                                  justifyContent: "center",
                                }}
                              >
                                
                                <Typography
                                  variant="subtitle1"
                                  color="text.secondary"
                                  component="div"
                                  sx={{mt:2, fontWeight:"bold",}}
                                >
                                  
                                  Create Your first resume

                                </Typography>
                                
                                    

                                <Typography
                                  variant="subtitle2"
                                  color="text.secondary"
                                  component="div"
                                  sx={{display:"flex", alignItems:"center", justifyContent:"center"}}
                                  
                                >
                                  <TipsAndUpdatesIcon sx={{color:'#ffc107', fontSize:25, mb:1, mr: 1.5, ml:1, mt:3}}/><br></br>
                                  Our AI Assistant will help you to create a professional resume in minutes
                                </Typography>
                                
                              </Box>
                            
                          </CardContent>
                          
                      </Card>
                      </Grow>
                      </>
                      //=================================================================================//
                      
                      :
                      
                      false
                      
                      }
                    </>
                  )}
                </div>
                {resumePage && resumeList.length > 10? 
                  <Stack alignItems="center">
                    <Pagination
                        sx={{mt:2}}
                        count={totalResumePages}
                        page={resumePage}
                        onChange={handleResumePageChange}
                    />
                  </Stack>
                  : ""}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                

              <div className={style.resumeCardContainer}>

                    

                {Array.isArray(coverLetterList) && coverLetterList.length > 0 ? (

                    pageOfCoverLetter.map((coverLetter) => (
                    
                    <CoverLetterCard
                      coverLetterOpen={coverLetterOpen}
                      coverLetterId={coverLetter.cover_letter_id}
                      coverLetterTitle={coverLetter.cover_letter_title}
                      filePath={coverLetter.file_path}
                      key={`coverLetterId${coverLetter.coverLetter_id}`}
                    />
                  ))
               
                ) : (

                   //==================================Cover Letter card for create new==========================//
                  <>
                    {Array.isArray(coverLetterList) && coverLetterList.length === 0
                      ?
                      <>
                        <Grow in={coverLetterOpen}>
                      <Card
                      className={style.coverLetterCard}
                      sx={{ display: "flex", flex: "wrap", p: 3}}
                    >
                      <CardContent
                          sx={{
                            flex: "1 0 auto",
                            flexDirection: "column",
                            pl: 1,
                            mr: 2,
                            height: 276.48,
                            width: 200,
                        pt: 12,
                          }}
                        >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                justifyContent: "center",
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              <IconButton 
                                color="primary" 
                                aria-label="add first cover letter"
                                onClick={() => navigate(`/coverLetterBuilder/create`)}
                              >
                                <AddIcon sx={{fontSize: 50, height:50, width: 50}}/>
                              </IconButton>
                            </Box>
                          
                        </CardContent>

                      <Divider className={style.verticalDivider} orientation="vertical" variant="middle" sx={{bgcolor:'grey.700'}} flexItem />
              
                      <CardContent
                          sx={{
                            flex: "1 0 auto",
                            flexDirection: "column",
                            justifyContent: "center",
                            // textAlign: "center",
                          // p:3,
                          pr:1,
                          pt:1,
                            mb: 1,
                            ml:2,
                            // height: 80,
                            width: 220,
                          }}
                        >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                                width:200,
                                justifyContent: "center",
                              }}
                            >
                              
                              <Typography
                                variant="subtitle1"
                                color="text.secondary"
                                component="div"
                                sx={{mt:2, fontWeight:"bold", pl:2}}
                              >
                                
                                Create Your first cover letter

                              </Typography>
                              
                                  

                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                                sx={{display:"flex", alignItems:"center", justifyContent:"center"}}
                                
                              >
                                <TipsAndUpdatesIcon sx={{color:'#ffc107', fontSize:25, mb:1, mr: 1.5, ml:1, mt:3}}/><br></br>
                                Create resume to generate a cover letter with our AI Assistant
                              </Typography>
                              
                            </Box>
                          
                        </CardContent>
                        
                    </Card>
                    </Grow>
                    </>

                      : false}
                  </>
                )}

              </div>
              {coverLetterPage && coverLetterList.length > 10 ?
                <Stack alignItems="center">
                  <Pagination
                      sx={{mt:2}}
                      count={totalCoverLetterPages}
                      page={coverLetterPage}
                      onChange={handleCoverLetterPageChange}
                  />
                </Stack>
                : ""
              }
              </CustomTabPanel>
            </Box>
            <Box
              className={style.speedDial}
              sx={{
                height: 320,
                transform: "translateZ(0px)",
                flexGrow: 1,
                position: "fixed",
                bottom: 110,
                right: 110,
              }}
            >
              <SpeedDial
                ariaLabel="SpeedDial openIcon example"
                sx={{ position: "absolute", bottom: 16, right: 16 , zIndex: 1000}}
                icon={<SpeedDialIcon openIcon={<EditIcon />} />}
              >
                {Array.isArray(actions) ? actions.map((action) => (
                  <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    onClick={() => navigate(`${action.navigate}`)}
                    // tooltipOpen
                  />
                ))
              :
              false
              }
              
              </SpeedDial>
            </Box>
          </div>
          :
          <>
         
          <Box sx={{ width: '100%' }}>
      <LinearProgress />
    </Box>
          </>
          }
        </>

          ) :  <Typography
          component="h1"
          variant="h6"
          //    align="left"
          sx={{ 
              mt: 4,
              fontWeight: 'bold',
              // textAlign: "center",
              // width: "100%",
          }}

          >
          Please <Link href="/login" underline="hover">login </Link>to access this page
          </Typography>
          }
   
      </>
  );
}
