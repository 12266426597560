import { FormEventHandler, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { localLogin } from "../hooks/authAPI";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../store";
import { useDispatch } from "react-redux";
import { login } from "../slices/AuthSlice";
import { jwtDecode } from "jwt-decode";
import { CircularProgress } from "@mui/material";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function LoginPage() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [loginLoading, setLoginLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const HandleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const data = new FormData(form);

    const emailOrUsername = data.get("username/email")!.toString();
    const password = data.get("password")!.toString();

    if (emailOrUsername === "" || password === "") {
      setErrorMessage("Please fill in username/email and password.");
      const invalidInput = form.querySelectorAll<HTMLInputElement>(":invalid");
      invalidInput[0].focus();
      return;
    }

    setLoginLoading(true);

    const success = await localLogin(emailOrUsername, password);
    if (success) {
      let decoded: { user_id: number; username: string } = jwtDecode(
        localStorage.getItem("token")!
      );

      console.log("decoded after login: ", decoded);

      dispatch(login({ username: decoded.username, user_id: decoded.user_id }));
      navigate("/");
    } else {
      // Error Handling, using react-toast is one possibility
      setErrorMessage("Invalid username/email or password.");
      setLoginLoading(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            sx={{
              mb: 2,
              fontWeight: "bold",
              fontFamily: "Exo, sans-serif",
            }}
          >
            Welcome back!
          </Typography>

          <Box
            component="form"
            onSubmit={HandleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              fullWidth
              name="username/email"
              id="username/email"
              label="Username/Email Address"
              autoComplete="username/email"
              autoFocus
              required
            />
            <TextField
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              required
            />
            {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
            
            <Typography sx={{ color: "#d32f2f" }}>{errorMessage}</Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, fontFamily: "Exo, sans-serif" ,textTransform: 'none'}}
            >
              {loginLoading !== true ? (
                "Login"
              ) : (
                <CircularProgress size="25px" color="inherit" />
              )}
            </Button>
            <Grid container sx={{justifyContent:"center"}}>
              {/* <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid> */}
              <Grid item>
                <Link href="/register" variant="body2">
                  {"Don't have an account? Register Now"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
