import { useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import style from "./coverLetterCard.module.css";

import { Button, Link, Skeleton } from "@mui/material";
import Divider from '@mui/material/Divider';

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import Grow from '@mui/material/Grow';

import { deleteCoverLetter } from "../hooks/dashboardAPI";
import * as React from "react";

import { RootState } from "../store";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";

import coverLetter from "../img/letter1.png";

type coverLetterCardProps = {
  coverLetterOpen: boolean;
  coverLetterId: number;
  coverLetterTitle: string;
  filePath: string;
};

export default function CoverLetterCard(props: coverLetterCardProps) {
  const navigate = useNavigate();

  const theme = useTheme();

  const queryClient = useQueryClient();
  const OnDeleteCoverLetter = useMutation({
    mutationFn: async (data: { coverLetterId: number }) =>
    deleteCoverLetter(data.coverLetterId),
    onSuccess: () => {
      console.log("onSuccess");
      queryClient.invalidateQueries({
        queryKey: ["coverLetterList"],
        exact: true,
      });
    },
  });

  const handleDelete = async () => {
    try {
      await OnDeleteCoverLetter.mutate({ coverLetterId: props.coverLetterId });
      handleClose()
    } catch (error) {
      console.error("An error occurred during deletion:", error);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [ coverLetterUrl, setCoverLetterUrl ] = React.useState<string>("")
  // const [rerender, setReRender] = React.useState<Boolean>(false)

  const isAuthenticated = useSelector<RootState>(
    (state) => state.auth.isAuthenticated
  );

  let user_id: number | null = null;
  if (isAuthenticated) {
    let decoded: { user_id: number; username: string } = jwtDecode(
      localStorage.getItem("token")!
    );
    user_id = decoded.user_id;
  }

//   const [ showSkeleton, setShowSkeleton ] = React.useState<boolean>(false)

//   const generateCoverLetter = async () => {
//     setShowSkeleton(true)
//     const coverLetterUrlObject = await generateCoverLetterFromAI(user_id!, props.coverLetterId)
//     setCoverLetterUrl(coverLetterUrlObject.fileUrl)
//     setShowSkeleton(false)      
//     const link = document.createElement('a')
//     link.href = coverLetterUrl
//     link.setAttribute('download','cover-letter.pdf')
//     document.body.appendChild(link)
//     link.click()
//     link.parentNode?.removeChild(link)
//   }

  // React.useEffect(()=>{
  //   if (coverLetterUrl !== "") {
  //     const link = document.createElement('a')
  //     link.href = coverLetterUrl
  //     link.setAttribute('download','cover-letter.pdf')
  //     document.body.appendChild(link)
  //     link.click()
  //     link.parentNode?.removeChild(link)
  //   }
  // },[coverLetterUrl])

  return (




    <>

    <Grow in={props.coverLetterOpen}>

      <Card
        className={style.coverLetterCard}
        sx={{ display: "flex", flex: "wrap", p: 3,  }}
      >
        <CardMedia
          component="img"
          sx={{ width: 200, height: 200, cursor: "pointer", mr:2, p:5, mt:4.8, mb:4.8}}
          image={coverLetter}
          alt="Resume picture"
          onClick={() => navigate(`/coverLetterBuilder/${props.coverLetterId}`)}
        />

        <Divider className={style.verticalDivider} orientation="vertical" variant="middle" sx={{bgcolor:'grey.700'}} flexItem />

        <Box sx={{ display: "flex", flexDirection: "column", width: 220, ml:2 }}>
          <CardContent
            sx={{
            //   flex: "1 0 auto",
              flexDirection: "column",
              pl: 1.2,
              mb: 1,
            //   height: 80,
            }}
          >
            <Typography
              component="div"
              variant="h6"
              onClick={() => navigate(`/coverLetterBuilder/${props.coverLetterId}`)}
              sx={{ cursor: "pointer", width: "100%", fontFamily: "Exo" }}
            >
              {props.coverLetterTitle ? <>{props.coverLetterTitle.length>40 ? props.coverLetterTitle.slice(0,40) + "...": props.coverLetterTitle }</>: "[Untitled]"}
            </Typography>
            {/* <Typography
              variant="subtitle2"
              color="text.secondary"
              component="div"
            >
              coverLetter id: {props.coverLetterId}
            </Typography> */}
          </CardContent>
          <Box
            sx={{
              display: "flex",
              // alignItems: "center",
              flexDirection: "column",
              // justifyContent: "flex-start",
            }}
          >
            
            {/* <Button
              sx={{
                textTransform: "none",
                gap: 1,
                justifyContent: "flex-start",
                fontFamily: "Montserrat, sans-serif",
              }}
              onClick={generateCoverLetter}
            >
              <HistoryEduIcon /> Generate Cover Letter
            </Button> */}

            <div>
              <a href={props.filePath} download="coverLetter.docx">
                <Button
                  sx={{
                    textTransform: "none",
                    gap: 1,
                    justifyContent: "flex-start",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  <DownloadIcon />
                  Download DOCX
                </Button>
              </a>
            </div>
            

          
            <Button
              sx={{
                textTransform: "none",
                gap: 1,
                justifyContent: "flex-start",
                fontFamily: "Montserrat, sans-serif",
              }}
              onClick={() => navigate(`/coverLetterBuilder/${props.coverLetterId}`)}
            >
              <EditIcon /> Edit
            </Button>
            <React.Fragment>
            <Button
              sx={{
                textTransform: "none",
                gap: 1,
                justifyContent: "flex-start",
                fontFamily: "Montserrat, sans-serif",
              }}
              onClick={handleClickOpen}
            >
              <DeleteForeverIcon /> Delete
            </Button>
            <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{fontWeight:'bold',fontFamily:'Exo'}} id="alert-dialog-title">
          {"Confirm Delete"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Are you sure you want to delete this cover letter?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{p:3, pt:0}}>
      
          <Button sx={{textTransform: "none", }} size='small' variant="contained" color="error" onClick={handleDelete} autoFocus>
             Delete
          </Button>
          <Button sx={{color:'black',textTransform: "none",}} size='small' onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
            {/* <Button sx={{ textTransform: "none", pl:0, justifyContent: "flex-start", fontFamily: "Montserrat, sans-serif",}}>
                more details
            </Button> */}
          </Box>
        </Box>
      </Card>
    
    </Grow>
    </>
  );
}
