import style from "./CoverLetterBuilder.module.css";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { createUser } from "../hooks/authAPI";
import { redirect, useNavigate } from "react-router-dom";
import { CircularProgress, LinearProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import BackupSharpIcon from '@mui/icons-material/BackupSharp';
import PreviewIcon from '@mui/icons-material/Preview';
import { Fab } from "@mui/material";

import logo from "../img/logo9.png";
import UserMenu from "../cp-user/UserMenu";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { jwtDecode } from "jwt-decode";

import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useState, useEffect, useRef } from "react";
import { updateCoverLetter, useLoadCoverLetterData } from "../hooks/coverLetterAPI";
import { GenerateCoverLetter } from "../hooks/generateCoverLetterAPI";
import CvPreviewer from "../cp-resume/CvPreviewer";
import { articleDescriptionSuggestion } from "../hooks/aiApi";
import { AIsuggestion } from "../cp-resume/AIsuggestion";


function checkString(string: any) {
    return /^[0-9]*$/.test(string);
  }
  

export default function CoverLetterBuilder(){

        //show preview after click
        const [showPreview, setShowPreview] = useState(false);
        const showPreviewButton = () => {
            setShowPreview(true);
        };
        const backToEditorButton = () => {
            setShowPreview(false);
        };

        //show the show preview cv button
        const [PreviewButton, setPreviewButton] = useState(false);
        const PreviewButtonAppear = () => {
            setPreviewButton(true);
        };

        //===================================================get user data from jwt========================================//

        const isAuthenticated = useSelector<RootState>(
            (state) => state.auth.isAuthenticated
          );
        
          let user = "";
          let user_id: number | null = null;
          if (isAuthenticated) {
            let decoded: { user_id: number; username: string } = jwtDecode(
              localStorage.getItem("token")!
            );
            // console.log("decoded", decoded);
            user = decoded.username;
            user_id = decoded.user_id;
          }
    //===================================get cover letter data============================================//

            let coverLetterId = null;
            let coverLetterData: any = null;
            const { cLetterId } = useParams();

            console.log("coverLetterId:", cLetterId);
            if (cLetterId != undefined && checkString(cLetterId)) {
                coverLetterId = parseInt(cLetterId);
            }
            coverLetterData = useLoadCoverLetterData(coverLetterId);
            console.log("coverLetterData at first:", coverLetterData);



          //*********************************redirect if not the owner of the cover letter or invalid cover letter id*********************************//

            if (coverLetterData?.message || !checkString(cLetterId)) {
                window.location.href = "/"
              }


        //===================================handle submission============================================//

        const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const data = new FormData(event.currentTarget);


            let coverLetterSubmitted = {
                userId: user_id,
                title: data.get("title"),
                firstName: data.get("firstName"),
                lastName: data.get("lastName"),
                email: data.get("email"),
                phone: data.get("phone"),
                position: data.get("targetPosition"),
                employerName: data.get("employerName"),
                employerCompany: data.get("company"),
                article: data.get("article"),
                coverLetterId: data.get("coverLetter_id"),
                jobRequirement: data.get("jobRequirement"),

            };

            console.log("coverLetterSubmitted:", coverLetterSubmitted);

            OnUpdateCoverLetter.mutate({ coverLetterSubmitted });

            //============Generate coverLetter docx=================//

            let generateCoverLetter = GenerateCoverLetter(coverLetterSubmitted)

            console.log("generateCoverLetter:", generateCoverLetter);
        
        }

        //===================================use mutation============================================//

        const queryClient = useQueryClient();
        const OnUpdateCoverLetter = useMutation({
            mutationFn: async (data: { coverLetterSubmitted: any }) =>
              updateCoverLetter(data.coverLetterSubmitted),
            onSuccess: () =>
              queryClient.invalidateQueries({
                queryKey: ["coverLetterData"],
                exact: true,
              }),
          });

        

        //===================================rerender============================================//
        // const [rerender, setReRender] = useState(false)
        // setReRender(v => !v)


        //===================================AI suggestion============================================//    
        const [rerender, setReRender] = useState(false)
        const CVUrlRef = useRef<string>('')
        const [ article, setArticle ] = useState<string>("")
        const [ jobTitle, setJobTitle ] = useState<string>("")
        const [ jobRequirement, setJobRequirement ] = useState<string>("")
   
        useEffect(() => {
            if(coverLetterData?.coverLetterData){
                setArticle(v => coverLetterData?.coverLetterData[0]?.article)
                setJobTitle(v => coverLetterData?.coverLetterData[0]?.position)
                setJobRequirement(v => coverLetterData?.coverLetterData[0]?.job_requirement)
            }
            },[coverLetterData])

            
            const OnHandleArticleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setArticle(event.currentTarget.value)
            }
            const OnHandleJobTitleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setJobTitle(event.currentTarget.value)
            }
        
            const OnHandleJobRequirementChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                setJobRequirement(event.currentTarget.value)
            }



        const changeArticle = (aiResult:string) => {
            setArticle(aiResult)
        }

        

    return (
        <div className={style.coverLetterBigContainer}>

        {isAuthenticated?
        (
            <>
            
        {
            coverLetterData &&
            coverLetterData.coverLetterData &&
            coverLetterData?.coverLetterData.length > 0 &&
            coverLetterData?.coverLetterData[0].user_id == user_id &&
            coverLetterData?.coverLetterData[0].coverLetter_id == coverLetterId ? (
          <div>




           
            {showPreview == false ? (
              <div className={style.coverLetterBuilderContainer}>
                <div className={style.coverLetterFormArea}>


                {coverLetterData.coverLetterData[0].file_path?
                <Fab
                    onClick={showPreviewButton}
                    className={style.previewButton}
                    variant="extended"
                    color="primary"
                    aria-label="Preview CV"
                    sx={{ textTransform: "none" }}
                  >
                    Preview CV
                  </Fab>
                   :
                  false
                }
                
                  
                  <Container component="main" maxWidth="md">
                    <CssBaseline />
                    <Box
                      sx={{
                        // marginTop: 2,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        component="form"
                        noValidate
                        onSubmit={handleSubmit}
                        sx={{ mt: 3 }}
                      >
                        
                        <Grid
                          container
                          // spacing={2}
                          sx={{ mb: 4, textAlign: "center" }}
                        >
                          <Grid
                            item
                            xs={12}
                            sx={{
                              borderBottom: 2,
                              borderColor: "#2196f3",
                            }}
                          >
                            <TextField
                              // size="small"
                              variant="standard"
                              sx={{
                                mb: 3,
                              }}
                              inputProps={{
                                // min: 0,
                                style: { textAlign: "center", fontSize: "20px", },
                                maxLength:32
                              }}
                              name="title"
                              id="title"
                              defaultValue={
                                coverLetterData?.coverLetterData[0].title
                                ? coverLetterData?.coverLetterData[0].title
                                : "[Untitled]"
                              }
                              label="Title"
                            />
                          </Grid>
                        </Grid>

                        {/* *******************************Cover Letter details******************************** */}
                        <Typography
                          component="h1"
                          variant="h6"
                          align="left"
                          sx={{
                            mb: 2,
                            fontWeight: "bold",
                          }}
                          className={style.coverLetterBuilderSectionTitle}
                        >
                          Personal Details
                        </Typography>
                        <Grid container spacing={2} sx={{ mb: 4 }}>
                          <TextField
                            size="small"
                            value={coverLetterData?.coverLetterData[0].coverLetter_id}
                            name="coverLetter_id"
                            sx={{ display: "none" }}
                          />
                          <Grid item xs={12} sm={6}>
                            <TextField
                              size="small"
                              variant="filled"
                              defaultValue={
                                coverLetterData?.coverLetterData[0].first_name
                              }
                              fullWidth
                              id="firstName"
                              label="First Name"
                              name="firstName"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              size="small"
                              variant="filled"
                              defaultValue={
                                coverLetterData?.coverLetterData[0].last_name
                              }
                              fullWidth
                              id="lastName"
                              label="Last Name"
                              name="lastName"
                            />
                          </Grid>

                         
                          
                          
                          <Grid item xs={12} sm={6}>
                            <TextField
                              size="small"
                              variant="filled"
                              defaultValue={coverLetterData?.coverLetterData[0].email}
                              fullWidth
                              id="email"
                              label="Email Address"
                              name="email"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              size="small"
                              variant="filled"
                              defaultValue={coverLetterData?.coverLetterData[0].phone}
                              fullWidth
                              name="phone"
                              label="Phone"
                              type="phone"
                              id="phone"
                            />
                          </Grid>
                          {/* <Grid item xs={12} >
                            <TextField
                              size="small"
                              variant="filled"
                            //   defaultValue={resumeData?.personalData[0].email}
                              fullWidth
                              id="address"
                              label="Address"
                              name="address"
                            />
                          </Grid> */}
                        
                      
                        </Grid>

                        <Typography
                          component="h1"
                          variant="h6"
                          align="left"
                          sx={{
                            mb: 2,
                            fontWeight: "bold",
                          }}
                          className={style.cvBuilderSectionTitle}
                        >
                          Job Details
                        </Typography>
                        <Grid container spacing={2} sx={{ mb: 4 }}>
                          <TextField
                            size="small"
                            // value={resumeId}
                            name="resume_id"
                            sx={{ display: "none" }}
                          />
                          
                          <Grid item xs={12} sm={6}>
                            <TextField
                              size="small"
                              name="employerName"
                              variant="filled"
                              defaultValue={
                                coverLetterData?.coverLetterData[0].employer_name
                              }
                              fullWidth
                              id="employerName"
                              label="Employer Name"
                              autoFocus
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              size="small"
                              variant="filled"
                              defaultValue={
                                coverLetterData?.coverLetterData[0].employer_company
                              }
                              fullWidth
                              id="company"
                              label="Company Name"
                              name="company"
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              size="small"
                              variant="filled"
                            //   defaultValue={
                            //     coverLetterData?.coverLetterData[0].position
                            //   }
                              value={jobTitle}
                              fullWidth
                              name="targetPosition"
                              label="Target Position"
                              id="targetPosition"
                              onChange={OnHandleJobTitleChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              size="small"
                              variant="filled"
                            //   defaultValue={
                            //     coverLetterData?.coverLetterData[0].job_requirement
                            //   }
                            value={jobRequirement}
                            multiline
                            minRows={5} 
                            maxRows={10}
                              fullWidth
                              id="jobRequirement"
                              label="Job Requirement"
                              name="jobRequirement"
                              onChange={OnHandleJobRequirementChange}
                              helperText="Our AI will use this to generate cover letter content. You can also use it as a reference to write your own cover letter."
                            />
                          </Grid>
                        
                        </Grid>

                        <Typography
                          component="h1"
                          variant="h6"
                          align="left"
                          sx={{
                            mb: 2,
                            fontWeight: "bold",
                          }}
                          className={style.cvBuilderSectionTitle}
                        >
                          Letter content
                        </Typography>
                        <Grid container spacing={2} sx={{ mb: 4 }}>
                        
                          <Grid item xs={12}>
                            <TextField
                              multiline
                              minRows={10}
                              maxRows={25}
                              size="small"
                              name="article"
                              variant="filled"
                            //   defaultValue={
                            //     coverLetterData?.coverLetterData[0].article
                            //   }
                              value={article}
                              fullWidth
                              id="article"
                              label="Cover letter content (250-400 word is recommended)) "
                              autoFocus
                              onChange={OnHandleArticleChange}
                            />

                            <AIsuggestion 
                              content={article}
                              handleChange={changeArticle}
                              aiApi={articleDescriptionSuggestion}
                              jobTitle={jobTitle}
                              jobRequirement={jobRequirement}
                              
                            />
                          </Grid>
                       
                        
                        </Grid>

                      


                      


                        

                        

                        {/* ************************************Submission***********************************/}
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{  mt: 3, mb: 2, fontFamily: "Exo, sans-serif", textTransform: "none" }}
                          onClick={PreviewButtonAppear}
                          startIcon={<BackupSharpIcon />}
                        >
                           {Array.isArray(coverLetterData?.coverLetterData) && coverLetterData?.coverLetterData[0].file_path? 
                           "Save to Update" : "Save to Preview"}
                           
                        </Button>
                      </Box>
                    </Box>
                  </Container>
                </div>
                
               
                
                <div className={style.userButton}>
                    <div></div>
                    <UserMenu/>
                </div>
                 <a href="/jobSearch">
                    <img className={style.navLogo} src={logo} alt="Logo" />
                </a>
                <div className={style.coverLetterPreviewArea}>
                    
                    

                
                    
                    <div className={style.docViewerHalfContainer}>
                        <div  className={style.docHalfViewer}>
                         
                            {
                            coverLetterData?.coverLetterData[0].file_path
                            
                            ?  
                            <>
                                <div className={style.halfPreviewNavContainer}>
                                    <Typography
                                        component="h1"
                                        variant="h5"
                                        align="left"
                                        sx={{ fontFamily:'Exo', fontWeight: 'bold', color:'white'}}
                                    >
                                        Preview
                                    </Typography>
                                    
                                    <>
                                        <a href={coverLetterData?.coverLetterData[0].file_path} download="coverLetter.docx">
                                            <Button startIcon={<DownloadIcon/>} size="small" variant="contained" sx={{ boxShadow:'none',textTransform: "none", justifyContent: "flex-start", fontFamily: "Montserrat, sans-serif",}}
                                            >
                                                Download DOCX
                                            </Button>
                                        </a>
                                    </>
                                    
                                    </div>
                                    <CvPreviewer 
                                    url={coverLetterData?.coverLetterData[0].file_path} 
                                    // rerender={rerender}
                                    />
                                    {/* <CvPreviewer url={CVUrlRef.current}/> */}
                            </>
                                
                                :
                                <>
                                    <div className={style.halfPreviewNavContainer}>
                                        <Typography
                                            component="h1"
                                            variant="h5"
                                            align="left"
                                            sx={{ fontFamily:'Exo', fontWeight: 'bold', color:'white'}}
                                        >
                                            Preview
                                        </Typography>
                                        
                                        
                                        
                                        </div>
                                    <div className={style.emptyPreviewArea}>
                                        <PreviewIcon sx={{fontSize:150, color:"grey.400"}}/>
                                        
                                        Save to Generate and Preview Cover Letter 
                                    </div>
                                </>
                                
                            }
                           
                            
                            

                        </div>
                    </div>
                
               
                </div>

              </div>
            ) 





            
            : 
            
            // below part is to show full width CV preview for mobile --> related to useState: showPreview
            
            
            
            
            (
              <div className={style.docViewerFullContainer}>
                <div className={style.fullPreviewNavContainer}>
                    
                    <Button 
                    variant="text" 
                    onClick={backToEditorButton}
                    sx={{textTransform: "none",
                        color: "white",
                        fontWeight: "bold",
                        fontSize:15,
                        alignItems: "center"}}
                    startIcon={ <ArrowBackIcon/>}
                    >
                        <div className={style.halfBackButton}>Back to Editor</div>
                    </Button>
                        <Typography
                            component="h1"
                            variant="h5"
                            align="left"
                            sx={{ fontFamily:'Exo', fontWeight: 'bold', color:'white'}}
                            className={style.previewText}
                        >
                            Preview
                        </Typography>
                    <>
                                <a href={coverLetterData?.coverLetterData[0].file_path} download="resume.docx">
                                    <Button startIcon={<DownloadIcon/>} size="small" variant="contained" sx={{ boxShadow:'none',textTransform: "none", justifyContent: "flex-start", fontFamily: "Montserrat, sans-serif",}}
                                    >
                                        Download DOCX
                                    </Button>
                                </a>
                    </>
                    
                </div>
                <div  className={style.docFullViewer}>

                    <CvPreviewer 
                    url={coverLetterData?.coverLetterData[0].file_path} 
                    // rerender={rerender}
                    />
                    {/* <CvPreviewer url={CVUrlRef.current}/> */}
                </div>
              </div>
            )}
        </div>
        
        ) : (
            <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
             
            )

    
           
    }

    </>
        )
        :
        
            
            <Typography
            component="h1"
            variant="h6"
         //    align="left"
            sx={{ 
                mt: 4,
                fontWeight: 'bold',
                // textAlign: "center",
                // width: "100%",
            }}
          
            >
            Please <Link href="/login" underline="hover">login </Link>to access this page
            </Typography>
        
    
        }

    
    </div>
    )
}