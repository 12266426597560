import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import style from "./CvBuilder.module.css";
import { Fab } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { OnHandleArrayDescriptionChange, changeSkillArrayDescription } from "./OnHandleArrayDescriptionChange";
import { AIsuggestion } from "./AIsuggestion";
import { skillDescriptionSuggestion } from "../hooks/aiApi";


type SkillProps = {
    skill: Array<any>;
    resumeId: number;
    deleteSkillFromDatabase: (
      event: React.MouseEvent<HTMLButtonElement>
    ) => void;
  };

  export default function Skill(props: SkillProps) {

    //**************add new skill*******************/
  
    const [newSkill, setNewSkill]: any[] = useState([]);
  
    const addSkill = () => {
      const newSkillEntry = { tmpSkill_id: Math.floor(Math.random() * 1000), skillName:'', value:'' };
      setNewSkill([...newSkill, newSkillEntry]);
    };
    console.log("newSkill", newSkill);
  
    //**************delete new skill***************//
  
    const deleteNewSkill = (event: any) => {
      console.log("Button id:", event.target.id);
      const id = parseInt(event.target.id);
      setNewSkill(newSkill.filter((item: any) => item.tmpSkill_id !== id));
    };
  
    //******************handle skill Description********************* */

    const [ skillDescription, setSkillDescription ] = useState<string[]>([])
    const [ skillName, setSkillName ] = useState<string[]>([])

    useEffect(()=>{
      if(props.skill){
        setSkillDescription(props.skill.map((item:any)=>item.description))
        setSkillName(props.skill.map((item:any)=>item.skill))
      }
    },[props])

    const OnHandleSkillDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index:number, skillDescription:Array<string>, setSkillDescription:Function) => {
      OnHandleArrayDescriptionChange(event, index, skillDescription, setSkillDescription)
    }

    const changeSkillDescription = (aiResult:string, index:number) => {setSkillDescription(
      skillDescription.map((element, i) => {return i === index ? aiResult : element})
    )}

    //******************Handle new added employment description******************* */

    const OnHandleNewSkillDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, tmpSkill_id:number) => {
      const updateNewSkillDescription = changeSkillArrayDescription(newSkill, event.currentTarget.value, tmpSkill_id)
      setNewSkill(updateNewSkillDescription)
    }

    const changeNewSkillDescription = (aiResult: string, indexFromAI:number) => {
      const updateNewSkillDescription = changeSkillArrayDescription (newSkill, aiResult, indexFromAI)
      setNewSkill(updateNewSkillDescription)
    }


    return (
      <div>
        <Typography
          component="h1"
          variant="h6"
          align="left"
          sx={{
            mb: 2,
            fontWeight: "bold",
          }}
          className={style.cvBuilderSectionTitle}
        >
          Skill
        </Typography>
        {props.skill.map((item: any, index: number) => (
          <Accordion key={item.skill_id.toString()} sx={{ mb: 1, p: 1 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              {item.skill? item.skill : "Unspecified"}
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                  container
                  key={item.skill_id.toString()}
                  sx={{
                  }}
              >
  
                  <TextField
                    size="small"
                    value={item.skill_id.toString()}
                    name="skill_id"
                    sx={{ display: "none" }}
                  />
                  <TextField
                    size="small"
                    value={item.resume_id.toString()}
                    name="resume_id"
                    sx={{ display: "none" }}
                  />
  
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    sx={{
                      padding: "10px",
                    }}
                  >
                    <TextField
                      size="small"
                      variant="filled"
                      name="skill"
                      defaultValue={item.skill}
                      // value={skillName[index]}
                      fullWidth
                      id="skill"
                      label="Skill"
                      sx={{ bb: 0 }}
                    />
                  </Grid>
                  <Grid
                item
                xs={12}
                
                sx={{
                  padding: "10px",
                }}
              >
                <TextField
                  multiline
                  rows={5}
                  size="small"
                  variant="filled"
                  // defaultValue={item.description}
                  value={skillDescription[index]}
                  name="skillDescription"
                  fullWidth
                  id="skillDescription"
                  label="Description (application and proficiency level etc.)"
                  onChange={(event)=>OnHandleSkillDescriptionChange(event,index,skillDescription,setSkillDescription)}
                />
                <AIsuggestion 
                  content={skillDescription[index]}
                  handleChange={changeSkillDescription}
                  aiApi={skillDescriptionSuggestion}
                  index={index}
                  skillName={skillName[index]}
                />
              </Grid>
                  
                  <Grid
                    item
                    xs={12}
                    container
                    sx={{
                      alignItems: "center",
                      // justifyContent: "space-between",
                      justifyContent: "center",
                      ml: 1,
                    }}
                  >
                    <Tooltip id={item.skill_id.toString()} title="Delete">
                      <IconButton
                        onClick={props.deleteSkillFromDatabase}
                        id={item.skill_id.toString()}
                        color="error"
                      >
                        <DeleteOutlineRoundedIcon
                          id={item.skill_id.toString()}
                          fontSize="small"
                        />
                      </IconButton>
                    </Tooltip>
                  </Grid>
              </Grid> 
            </AccordionDetails>
          </Accordion>
        ))}
        {newSkill.map((item: any) => (
          <Accordion
            key={item.tmpSkill_id.toString()}
            sx={{ mb: 1, p: 1 }}
            defaultExpanded
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Untitled
            </AccordionSummary>
            <AccordionDetails>
              <Grid
                            container
                            //*******set key for each component mapped******
                            key={item.tmpSkill_id}
                            sx={{
                              
                            }}
                          >
              <TextField
                size="small"
                value={props.resumeId.toString()}
                name="resume_id"
                sx={{ display: "none" }}
              />
  
              <Grid
                item
                xs={12}
                sm={6}
                sx={{
                  padding: "10px",
                }}
              >
                <TextField
                  size="small"
                  variant="filled"
                  name="skill"
                  fullWidth
                  id="skill"
                  label="Skill"
                />
              </Grid>
              <Grid
                item
                xs={12}
                
                sx={{
                  padding: "10px",
                }}
              >
                <TextField
                  multiline
                  rows={5}
                  size="small"
                  variant="filled"
                  name="skillDescription"
                  fullWidth
                  id="skillDescription"
                  label="Description (application and proficiency level etc.)"
                  value={item.value}
                  onChange={(event)=>OnHandleNewSkillDescriptionChange(event,item.tmpSkill_id)}
                />
                <AIsuggestion 
                  content={item.value}
                  handleChange={changeNewSkillDescription}
                  aiApi={skillDescriptionSuggestion}
                  index={item.tmpSkill_id}
                  skillName={item.skillName}
                /> 
              </Grid>
              
            
            
              <Grid
                item
                xs={12}
                container
                sx={{
                  justifyContent: "center",
                }}
              >
                <Tooltip id={item.tmpSkill_id.toString()} title="Delete">
                  <IconButton
                    onClick={deleteNewSkill}
                    id={item.tmpSkill_id.toString()}
                    color="error"
                  >
                    <DeleteOutlineRoundedIcon
                      id={item.tmpSkill_id.toString()}
                      fontSize="small"
                    />
                  </IconButton>
                </Tooltip>
              </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
  
        <Button
          onClick={addSkill}
          sx={{ textTransform: "none", mb: 4 }}
        >
          + Add Skill
        </Button>
      </div>
    );
  }
  