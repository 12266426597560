import * as React from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import { AppDispatch, RootState } from '../store';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../slices/AuthSlice';
import { jwtDecode } from 'jwt-decode';



export default function UserMenu(){

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const logoutButton = () => {
        dispatch(logout());
        navigate("/");
        // window.location.href = "/";
      };
      
    const isAuthenticated = useSelector<RootState>(
    (state) => state.auth.isAuthenticated
    );

    let user = "";
    let user_id: number | null = null;
    if (isAuthenticated) {
    let decoded: { user_id: number; username: string } = jwtDecode(
        localStorage.getItem("token")!
    );
    
    user = decoded.username;
    user_id = decoded.user_id;
    }
    
    return (
        <React.Fragment>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
          }}
        >
        
          <Tooltip title={user}>
            <IconButton
              onClick={handleClick}
              size="small"
              sx={{ ml: 2,  }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar sx={{ width: 32, height: 32, }}>
                {user[0]}
              </Avatar>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              borderRadius: 2,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              // "&::before": {
              //   content: '""',
              //   display: "block",
              //   position: "absolute",
              //   top: 0,
              //   right: 14,
              //   width: 10,
              //   height: 10,
              //   bgcolor: "background.paper",
              //   transform: "translateY(-50%) rotate(45deg)",
              //   zIndex: 0,
              // },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onClick={() => navigate(`/profile`)}
            style={{ textDecoration: "none" }}
          >
            <ListItemIcon>
                <PersonIcon fontSize="small"/> 
            </ListItemIcon>
             Profile
          </MenuItem>

          {/* <MenuItem component={Link} to={`/dashboard/uid=${user_id}`} style={{ textDecoration: 'none' }}> */}
          <MenuItem
            onClick={() => navigate(`/dashboard`)}
            style={{ textDecoration: "none" }}
          >
            <ListItemIcon>
                <DashboardIcon fontSize="small"/> 
            </ListItemIcon> Dashboard
          </MenuItem>
          <Divider />
          <MenuItem onClick={logoutButton}>
            <ListItemIcon>
              <Logout fontSize="small" sx={{color:"#2962ff"}}/>
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </React.Fragment>
    );
}