import { useSelector } from "react-redux";
import { RootState } from "../store";
import { jwtDecode } from "jwt-decode";
import { updateProfile, useLoadUserProfile } from "../hooks/userAPI";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { IconButton, InputLabel } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import Input from "@mui/material/Input";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
// import { styled } from "@mui/material/styles";

import style from "./UserProfile.module.css";
import { useState } from "react";


import { FileUpload } from "@mui/icons-material";
import { MuiFileInput } from "mui-file-input";
import { useMutation, useQueryClient } from "@tanstack/react-query";

// const VisuallyHiddenInput = styled("input")({
//   clip: "rect(0 0 0 0)",
//   clipPath: "inset(50%)",
//   height: 1,
//   overflow: "hidden",
//   // position: "absolute",
//   bottom: 0,
//   left: 0,
//   whiteSpace: "nowrap",
//   width: 1,
// });

export default function UserProfile() {
  //hover over profile picture to edit
  const [hoverEditPhoto, setHoverEditPhoto] = useState(false);
  const handleMouseOver = () => {
    setHoverEditPhoto(true);
  };
  const handleMouseOut = () => {
    setHoverEditPhoto(false);
  };
  //

  const [value, setValue] = useState(null);
  const handleChange = (newValue: any) => {
    setValue(newValue);
  };


  const [editPhoto, setEditPhoto] = useState(false);
  const handleEditPhoto = () => {
    setEditPhoto(!editPhoto);
  }

  const isAuthenticated = useSelector<RootState>(
    (state) => state.auth.isAuthenticated
  );

  let user = "";
  let user_id: number | null = null;
  if (isAuthenticated) {
    let decoded: { user_id: number; username: string } = jwtDecode(
      localStorage.getItem("token")!
    );
    // console.log("decoded", decoded);
    user = decoded.username;
    user_id = decoded.user_id;
  }

  const userProfile = useLoadUserProfile(user_id);
  // console.log("userProfile", userProfile);

  const defaultTheme = createTheme();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      userName: data.get("userName"),
      email: data.get("email"),
    });
    const firstName = data.get("firstName");
    const lastName = data.get("lastName")
    const email = data.get("email")
    const username = data.get("username")

    const updatedProfile={
      userId: user_id,
      firstName: firstName,
      lastName: lastName,
      email: email,
      username: username,
    };

    OnUpdateProfile.mutate({ updatedProfile })
  };


  //===================================use mutation============================================//

  const queryClient = useQueryClient();
  const OnUpdateProfile = useMutation({
      mutationFn: async (data: { updatedProfile: any }) =>
        updateProfile(data.updatedProfile),
      onSuccess: () =>
        queryClient.invalidateQueries({
          queryKey: ["userProfile"],
          exact: true,
        }),
    });


  return (
    <div className={style.profileContainer}>
      {Array.isArray(userProfile) ? (
        <ThemeProvider theme={defaultTheme}>
          <Container component="main" sx={{maxWidth:400}}>
            <CssBaseline />
            <Box
              sx={{
                marginTop: 7,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // padding: 3,
                // border: "1px solid #e0e0e0",
                // borderRadius: "15px",
                
              }}
            >
              <Typography component="h1" variant="h5"sx={{ 
                                mb: 2,
                                fontWeight: 'bold',
                                fontFamily: "Exo, sans-serif"
                             }}>
                Account Profile
              </Typography>
              <div className={style.userAvatarContainer}>
                <Avatar
                  alt={userProfile[0].username}
                  src={userProfile[0].profile_photo}
                  sx={{
                    width: 180,
                    height: 180,
                    marginTop: 0,
                  }}
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                >
                  {hoverEditPhoto? (
                    <IconButton className={style.profilePictureEditButton} onClick={handleEditPhoto}>
                      <EditIcon />
                    </IconButton>
                  ) : (
                    
                    <Typography component="h1" variant="h5"sx={{
                      
                      fontFamily: "Exo, sans-serif"
                   }}>
                      {userProfile[0].username}
                    </Typography>
                  )}
                </Avatar>
              </div>

              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
            
                {editPhoto ? (
                <>
                  {/* <MuiFileInput value={value} onChange={handleChange} /> */}
                  {/* <input
                  type="file"
                  accept="image/*"
                  id="contained-button-file"
                  /> */}


                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                >
                  Upload file
                  {/* <VisuallyHiddenInput type="file" /> */}
                </Button>
                <TextField type="file" fullWidth/>
              
               
                </>) : (
                  ""
                )}

                <Grid container spacing={1.5}>
                  <Grid item xs={12} sm={6}>
                    <InputLabel>First Name</InputLabel>
                    <TextField
                      size="small"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      defaultValue={userProfile[0].first_name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel>Last Name</InputLabel>
                    <TextField
                    size="small"
                      required
                      fullWidth
                      id="lastName"
                      defaultValue={userProfile[0].last_name}
                      name="lastName"
                     
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Username</InputLabel>
                    <TextField
                    size="small"
                      required
                      fullWidth
                      id="username"
                      defaultValue={userProfile[0].username}
                      name="username"
                      
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel>Email</InputLabel>
                    <TextField
                    size="small"
                      required
                      fullWidth
                      id="email"
                      defaultValue={userProfile[0].user_email}
                      name="email"
                      
                    />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2,textTransform: "none",
                  fontFamily: "Exo, sans-serif" }}
                  
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      ) : (
        ""
      )}
    </div>
  );
}
