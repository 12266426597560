import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import style from "./CvBuilder.module.css";
import { Fab } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";




type ReferenceProps = {
  reference: Array<any>;
  resumeId: number;
  deleteReferenceFromDatabase: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
};

export default function Reference(props: ReferenceProps) {
  //**************add new reference*******************/

  const [newReference, setNewReference]: any[] = useState([]);

  const addReference = () => {
    const newRefEntry = { tmpRef_id: Math.floor(Math.random() * 1000) };
    setNewReference([...newReference, newRefEntry]);
  };
  console.log("newReference", newReference);

  //**************delete new reference***************//

  const deleteNewReference = (event: any) => {
    console.log("Button id:", event.target.id);
    const id = parseInt(event.target.id);
    setNewReference(newReference.filter((item: any) => item.tmpRef_id !== id));
  };

  return (
    <div>
      <Typography
        component="h1"
        variant="h6"
        align="left"
        sx={{
          mb: 2,
          fontWeight: "bold",
        }}
        className={style.cvBuilderSectionTitle}
      >
        Reference
      </Typography>
      {props.reference.map((item: any, index: number) => (
        <Accordion key={item.reference_id.toString()} sx={{ mb: 1, p: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            {item.referee_name? item.referee_name : "Unspecified"}
          </AccordionSummary>
          <AccordionDetails>
            <Grid
                container
                key={item.reference_id.toString()}
                sx={{
                }}
            >

            <TextField
              size="small"
              value={item.reference_id.toString()}
              name="reference_id"
              sx={{ display: "none" }}
            />
            <TextField
              size="small"
              value={item.resume_id.toString()}
              name="resume_id"
              sx={{ display: "none" }}
            />

            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                name="refereeName"
                defaultValue={item.referee_name}
                fullWidth
                id="refereeName"
                label="Name"
                sx={{ bb: 0 }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                fullWidth
                defaultValue={item.referee_position}
                id="refereePosition"
                label="Position"
                name="refereePosition"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                fullWidth
                defaultValue={item.organization}
                id="organization"
                label="Organization"
                name="organization"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                fullWidth
                defaultValue={item.relationship}
                name="relationship"
                label="Relationship"
                id="relationship"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                fullWidth
                defaultValue={item.phone}
                name="refereePhone"
                label="Phone"
                id="refereePhone"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                fullWidth
                defaultValue={item.email}
                name="refereeEmail"
                label="Email"
                id="refereeEmail"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: "10px",
              }}
            >
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{
                alignItems: "center",
                // justifyContent: "space-between",
                justifyContent: "center",
                ml: 1,
              }}
            >
              <Tooltip id={item.reference_id.toString()} title="Delete">
                <IconButton
                  onClick={props.deleteReferenceFromDatabase}
                  id={item.reference_id.toString()}
                  color="error"
                >
                  <DeleteOutlineRoundedIcon
                    id={item.reference_id.toString()}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            </Grid> 
          </AccordionDetails>
        </Accordion>
      ))}
      {newReference.map((item: any) => (
        <Accordion
          key={item.tmpRef_id.toString()}
          sx={{ mb: 1, p: 1 }}
          defaultExpanded
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Untitled
          </AccordionSummary>
          <AccordionDetails>
            <Grid
                          container
                          //*******set key for each component mapped******
                          key={item.tmpEmp_id}
                          sx={{
                            
                          }}
                        >
            <TextField
              size="small"
              value={props.resumeId.toString()}
              name="resume_id"
              sx={{ display: "none" }}
            />

            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                name="refereeName"
                fullWidth
                id="refereeName"
                label="Name"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                fullWidth
                id="refereePosition"
                label="Position"
                name="refereePosition"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                fullWidth
                id="organization"
                label="Organization"
                name="organization"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                fullWidth
                name="relationship"
                label="Relationship"
                id="relationship"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                name="refereePhone"
                fullWidth
                id="refereePhone"
                label="Phone"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                name="refereeEmail"
                fullWidth
                id="refereeEmail"
                label="Email"
              />
            </Grid>
          
          
            <Grid
              item
              xs={12}
              container
              sx={{
                justifyContent: "center",
              }}
            >
              <Tooltip id={item.tmpRef_id.toString()} title="Delete">
                <IconButton
                  onClick={deleteNewReference}
                  id={item.tmpRef_id.toString()}
                  color="error"
                >
                  <DeleteOutlineRoundedIcon
                    id={item.tmpRef_id.toString()}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}

      <Button
        onClick={addReference}
        sx={{ textTransform: "none", mb: 4 }}
      >
        + Add Reference
      </Button>
    </div>
  );
}
