import CvPreviewer from "./CvPreviewer";
import UserMenu from "../cp-user/UserMenu";
import style from "./CvBuilder.module.css";
import { Fab } from "@mui/material";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { createUser } from "../hooks/authAPI";
import { redirect, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import BackupSharpIcon from "@mui/icons-material/BackupSharp";
import PreviewIcon from "@mui/icons-material/Preview";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import cvTemplate1 from "../img/cvTemplate1.png";
import cvTemplate2 from "../img/cvTemplate2.png";

import { useState, useEffect, useRef } from "react";

import {
  createEducation,
  createLanguage,
  createReference,
  createSkill,
  createWorkingExperience,
  deleteEducation,
  deleteLanguage,
  deleteReference,
  deleteSkill,
  deleteWorkingExperience,
  updateEducation,
  updateLanguage,
  updatePersonalData,
  updateReference,
  updateSkill,
  updateWorkingExperience,
  useLoadResumeData,
} from "../hooks/resumeAPI";

import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GenerateCv } from "../hooks/generateCvAPI";

import * as React from "react";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  educationDescriptionSuggestion,
  employmentDescriptionSuggestion,
  extraCurricularActivityDescriptionSuggestion,
  summaryDescriptionSuggestion,
} from "../hooks/aiApi";
import { Description } from "@mui/icons-material";
import LinearProgress from '@mui/material/LinearProgress';


import { useSelector } from "react-redux";
import { RootState } from "../store";
import { jwtDecode } from "jwt-decode";
import { AIsuggestion } from "./AIsuggestion";

import logo from "../img/logo9.png";

import EmploymentHistory from "./Employment";
import Education from "./Education";
import Reference from "./Reference";
import Language from "./Language";
import Skill from "./Skill";
import { red } from "@mui/material/colors";


function checkString(string: any) {
  return /^[0-9]*$/.test(string);
}

export default function CvBuilder() {
  const isAuthenticated = useSelector<RootState>(
    (state) => state.auth.isAuthenticated
  );

  let user = "";
  let user_id: number | null = null;
  if (isAuthenticated) {
    let decoded: { user_id: number; username: string } = jwtDecode(
      localStorage.getItem("token")!
    );
    // console.log("decoded", decoded);
    user = decoded.username;
    user_id = decoded.user_id;
  }

  let resumeId = null;
  let resumeData: any = null;
  const { cvId } = useParams();

  console.log("cvId:", cvId);
  if (cvId != undefined && checkString(cvId)) {
    resumeId = parseInt(cvId);
  }
  resumeData = useLoadResumeData(resumeId);
  console.log("resumeData at first:", resumeData);



  //*********************************redirect if not the owner of the resume or invalid resume id*********************************//
  
  if (resumeData?.message || !checkString(cvId)) {
    window.location.href = "/"
  }
 


  // ******************************** submit data for ChatGPT suggestion ********************************* *//
  const CVUrlRef = useRef<string>("");
  const [summary, setSummary] = useState<string>("");
  const [rerender, setReRender] = useState(false);

  useEffect(() => {
    if (resumeData?.personalData) {
      setSummary((v) => resumeData?.personalData[0]?.summary);

    }
  }, [resumeData]);

  const OnHandleSummaryChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSummary(event.currentTarget.value);
  };

  const changeSummary = (aiResult: string) => {
    setSummary(aiResult);
  };

  //===================================== choosing cv template=============================//

  const chooseTemplate1 = () => {
    resumeData.personalData[0].template = "cvTemplate1.docx";
    setSelectedTemplate("cvTemplate1.docx");
  };
  const chooseTemplate2 = () => {
    resumeData.personalData[0].template = "cvTemplate2.docx";
    setSelectedTemplate("cvTemplate2.docx");
  };

  const [selectedTemplate, setSelectedTemplate] = useState("");

  //****************************handle submission************************//

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    //========================default template=============================//

    if (resumeData?.personalData[0].template == null) {
      resumeData.personalData[0].template = "cvTemplate1.docx";
    }

    //***********************handle personal data*******************************//
    let personalDataSubmitted = {
      title: data.get("title"),
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      phone: data.get("phone"),
      email: data.get("email"),
      targetJob: data.get("targetPosition"),
      summary: data.get("summary"),
      resume_id: data.get("resume_id"),
      template: resumeData?.personalData[0].template,
    };
    console.log("personalDataSubmitted", personalDataSubmitted);

    //**********create/update personal data useMutation**********//

    if (personalDataSubmitted.resume_id !== null) {
      OnUpdatePersonalData.mutate({ personalDataSubmitted });
    } else {
      console.log("new resume need to be created");
    }

    //************************************************************************//

    //************************handle employment history******************************//

    //**********create object array for employment history**********//
    let jobTitle = data.getAll("jobTitle");
    let company = data.getAll("company");
    let startDate = data.getAll("startDate");
    let endDate = data.getAll("endDate");
    let jobDescription = data.getAll("jobDescription");
    let experience_id = data.getAll("experience_id");
    let resumeId = data.getAll("resume_id");

    let employmentHistorySubmitted = jobTitle.map((value, i) => {
      return {
        jobTitle: value,
        company: company[i],
        startDate: startDate[i],
        endDate: endDate[i],
        jobDescription: jobDescription[i],
        experienceId: experience_id[i],
        resumeId: resumeId[i],
      };
    });
    console.log("employmentHistorySubmitted", employmentHistorySubmitted);

    //**********create/update/delete working experience useMutation**********//

    let existingWorkingExperienceSubmitted = employmentHistorySubmitted.filter(
      (item: any) => item.experienceId
    );
    console.log(
      "existingWorkingExperienceSubmitted",
      existingWorkingExperienceSubmitted
    );

    let newWorkingExperienceSubmitted = employmentHistorySubmitted.filter(
      (item: any) => !item.experienceId
    );
    console.log("newWorkingExperienceSubmitted", newWorkingExperienceSubmitted);

    //**********update existing working experience**********//
    if (existingWorkingExperienceSubmitted.length > 0) {
      for (let experience of existingWorkingExperienceSubmitted) {
        OnUpdateWorkingExperience.mutate({
          existingWorkingExperienceSubmitted: experience,
        });
      }
    }

    //**********create new working experience**********//
    // if (newWorkingExperienceSubmitted.length > 0) {
    //   for (let experience of newWorkingExperienceSubmitted) {
    //     OnCreateWorkingExperience.mutate({
    //       newWorkingExperienceSubmitted: experience,
    //     });
    //   }
    // }


    if (newWorkingExperienceSubmitted.length > 0) {
    
        OnCreateWorkingExperience.mutate({
          newWorkingExperienceSubmitted: newWorkingExperienceSubmitted,
        });
      }
    




    //**********delete working experience**********//
    if (deleteEmploymentHistoryArrayState.length > 0) {
      for (let id of deleteEmploymentHistoryArrayState) {
        if (+id) {
          let deletedExperienceId = +id;
          OnDeleteWorkingExperience.mutate({ deletedExperienceId });
        }
      }
    }

    //************************************************************************//
    //********************************************handle education******************************//

    let educationTitle = data.getAll("educationTitle");
    let school = data.getAll("school");
    let eduStartDate = data.getAll("eduStartDate");
    let eduEndDate = data.getAll("eduEndDate");
    let eduDescription = data.getAll("eduDescription");
    let education_id = data.getAll("education_id");

    let educationSubmitted = educationTitle.map((value, i) => {
      return {
        educationTitle: value,
        school: school[i],
        eduStartDate: eduStartDate[i],
        eduEndDate: eduEndDate[i],
        eduDescription: eduDescription[i],
        educationId: education_id[i],
        resumeId: resumeId[i],
      };
    });

    console.log("educationSubmitted", educationSubmitted);

    //**********create/update/delete working experience useMutation**********//

    let existingEducationSubmitted = educationSubmitted.filter(
      (item: any) => item.educationId
    );

    console.log("existingEducationSubmitted", existingEducationSubmitted);

    let newEducationSubmitted = educationSubmitted.filter(
      (item: any) => !item.educationId
    );

    console.log("newEducationSubmitted", newEducationSubmitted);

    //**********update existing education**********//
    if (existingEducationSubmitted.length > 0) {
      for (let education of existingEducationSubmitted) {
        OnUpdateEducation.mutate({ existingEducationSubmitted: education });
      }
    }

    //**********create new education**********//

    // if (newEducationSubmitted.length > 0) {
    //   for (let education of newEducationSubmitted) {
    //     OnCreateEducation.mutate({ newEducationSubmitted: education });
    //   }
    // }

    if (newEducationSubmitted.length > 0) {
      
        OnCreateEducation.mutate({ newEducationSubmitted: newEducationSubmitted });
      
    }



    //**********delete education**********//
    if (deleteEducationArrayState.length > 0) {
      for (let id of deleteEducationArrayState) {
        if (+id) {
          let deletedEducationId = +id;
          OnDeleteEducation.mutate({ deletedEducationId });
        }
      }
    }

    //********************************************handle Skill****************************//

    let skill = data.getAll("skill");
    let skillDescription = data.getAll("skillDescription");
    let skillId = data.getAll("skill_id");

    let skillSubmitted = skill.map((value, i) => {
      return {
        skill: value,
        skillDescription: skillDescription[i],
        skillId: skillId[i],
        resumeId: resumeId[i],
      };
    });

    console.log(
      "skillSubmitted********************************",
      skillSubmitted
    );

    //**********create/update/delete working experience useMutation**********//

    let existingSkillSubmitted = skillSubmitted.filter(
      (item: any) => item.skillId
    );

    console.log("existingSkillSubmitted", existingSkillSubmitted);

    let newSkillSubmitted = skillSubmitted.filter((item: any) => !item.skillId);

    console.log("newSkillSubmitted", newSkillSubmitted);

    //**********update existing skill**********//
    if (existingSkillSubmitted.length > 0) {
      for (let skill of existingSkillSubmitted) {
        OnUpdateSkill.mutate({ existingSkillSubmitted: skill });
      }
    }

    //**********create new skill**********//

    // if (newSkillSubmitted.length > 0) {
    //   for (let skill of newSkillSubmitted) {
    //     OnCreateSkill.mutate({ newSkillSubmitted: skill });
    //   }
    // }

    if (newSkillSubmitted.length > 0) {
      
        OnCreateSkill.mutate({ newSkillSubmitted: newSkillSubmitted });
      
    }

    //**********delete skill**********//
    if (deleteSkillArrayState.length > 0) {
      for (let id of deleteSkillArrayState) {
        if (+id) {
          let deletedSkillId = +id;
          OnDeleteSkill.mutate({ deletedSkillId });
        }
      }
    }

    //********************************************handle Reference******************************//

    let refereeName = data.getAll("refereeName");
    let refereePosition = data.getAll("refereePosition");
    let organization = data.getAll("organization");
    let relationship = data.getAll("relationship");
    let refereePhone = data.getAll("refereePhone");
    let refereeEmail = data.getAll("refereeEmail");
    let referenceId = data.getAll("reference_id");

    let referenceSubmitted = refereeName.map((value, i) => {
      return {
        refereeName: value,
        refereePosition: refereePosition[i],
        organization: organization[i],
        relationship: relationship[i],
        refereePhone: refereePhone[i],
        refereeEmail: refereeEmail[i],
        resumeId: resumeId[i],
        referenceId: referenceId[i],
      };
    });

    console.log("referenceSubmitted", referenceSubmitted);

    //**********create/update/delete working experience useMutation**********//

    let existingReferenceSubmitted = referenceSubmitted.filter(
      (item: any) => item.referenceId
    );

    console.log("existingReferenceSubmitted", existingReferenceSubmitted);

    let newReferenceSubmitted = referenceSubmitted.filter(
      (item: any) => !item.referenceId
    );

    console.log("newReferenceSubmitted", newReferenceSubmitted);

    //**********update existing reference**********//
    if (existingReferenceSubmitted.length > 0) {
      for (let reference of existingReferenceSubmitted) {
        OnUpdateReference.mutate({ existingReferenceSubmitted: reference });
      }
    }

    //**********create new reference**********//

    // if (newReferenceSubmitted.length > 0) {
    //   for (let reference of newReferenceSubmitted) {
    //     OnCreateReference.mutate({ newReferenceSubmitted: reference });
    //   }
    // }

    if (newReferenceSubmitted.length > 0) {
     
        OnCreateReference.mutate({ newReferenceSubmitted: newReferenceSubmitted });
 
    }

    //**********delete reference**********//
    if (deleteReferenceArrayState.length > 0) {
      for (let id of deleteReferenceArrayState) {
        if (+id) {
          let deletedReferenceId = +id;
          OnDeleteReference.mutate({ deletedReferenceId });
        }
      }
    }

    //********************************************handle Language******************************//

    let language = data.getAll("language");
    let level = data.getAll("level");
    let languageId = data.getAll("language_id");

    let languageSubmitted = language.map((value, i) => {
      return {
        language: value,
        level: level[i],
        languageId: languageId[i],
        resumeId: resumeId[i],
      };
    });

    console.log(
      "languageSubmitted********************************",
      languageSubmitted
    );

    //**********create/update/delete working experience useMutation**********//

    let existingLanguageSubmitted = languageSubmitted.filter(
      (item: any) => item.languageId
    );

    console.log("existingLanguageSubmitted", existingLanguageSubmitted);

    let newLanguageSubmitted = languageSubmitted.filter(
      (item: any) => !item.languageId
    );

    console.log("newLanguageSubmitted", newLanguageSubmitted);

    //**********update existing language**********//
    if (existingLanguageSubmitted.length > 0) {
      for (let language of existingLanguageSubmitted) {
        OnUpdateLanguage.mutate({ existingLanguageSubmitted: language });
      }
    }

    //**********create new language**********//

    // if (newLanguageSubmitted.length > 0) {
    //   for (let language of newLanguageSubmitted) {
    //     OnCreateLanguage.mutate({ newLanguageSubmitted: language });
    //   }
    // }

    
    if (newLanguageSubmitted.length > 0) {
      
        OnCreateLanguage.mutate({ newLanguageSubmitted: newLanguageSubmitted });
      
    }

    

    //**********delete language**********//
    if (deleteLanguageArrayState.length > 0) {
      for (let id of deleteLanguageArrayState) {
        if (+id) {
          let deletedLanguageId = +id;
          OnDeleteLanguage.mutate({ deletedLanguageId });
        }
      }
    }

    //*********************** Generate CV in backend************************************** */

    let cvInformation = {
      firstName: data.get("firstName")!.toString(),
      lastName: data.get("lastName")!.toString(),
      phone: data.get("phone")!,
      email: data.get("email")!.toString(),
      targetPosition: data.get("targetPosition")!.toString(),
      summary: data.get("summary")!.toString(),
      title: data.get("title")!.toString(),
      resumeId: resumeData?.personalData[0].resume_id,
      employmentHistory: employmentHistorySubmitted,
      education: educationSubmitted,
      reference: referenceSubmitted,
      skill: skillSubmitted,
      language: languageSubmitted,
      cvTemplate: personalDataSubmitted.template,
    };

    GenerateCv(cvInformation);

    let filePath = await GenerateCv(cvInformation);
    // CVUrlRef.current = filePath.fileUrl;

    //*********reset employment history state after submission
    setNewEmploymentHistory([]);
    setDeleteEmploymentHistoryArrayState([]);
    setDeleteEducationArrayState([]);
    setDeleteReferenceArrayState([]);
    setReRender((v) => !v);
    // location.reload()
  };

  //show preview after click
  const [showPreview, setShowPreview] = useState(false);
  const showPreviewButton = () => {
    setShowPreview(true);
  };
  const backToEditorButton = () => {
    setShowPreview(false);
  };

  //show the show preview cv button
  const [PreviewButton, setPreviewButton] = useState(false);
  const PreviewButtonAppear = () => {
    setPreviewButton(true);
  };

  //*****************************handle personal data****************//

  const queryClient = useQueryClient();
  const OnUpdatePersonalData = useMutation({
    mutationFn: async (data: { personalDataSubmitted: any }) =>
      updatePersonalData(data.personalDataSubmitted),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["resumeData"],
        exact: true,
      }),
  });

  //*******************************handle employment history********************************//

  const OnUpdateWorkingExperience = useMutation({
    mutationFn: async (data: { existingWorkingExperienceSubmitted: any }) =>
      updateWorkingExperience(data.existingWorkingExperienceSubmitted),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["resumeData"],
        exact: true,
      }),
  });

  const OnCreateWorkingExperience = useMutation({
    mutationFn: async (data: { newWorkingExperienceSubmitted: any }) =>
      createWorkingExperience(data.newWorkingExperienceSubmitted),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["resumeData"],
        exact: true,
      }),
  });

  const OnDeleteWorkingExperience = useMutation({
    mutationFn: async (data: { deletedExperienceId: any }) =>
      deleteWorkingExperience(data.deletedExperienceId),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["resumeData"],
        exact: true,
      }),
  });

  //**************add new employment history*******************/
  const [newEmploymentHistory, setNewEmploymentHistory]: any[] = useState([]);

  const addEmploymentHistory = () => {
    const newEntry = { tmpEmp_id: Math.floor(Math.random() * 1000) };
    setNewEmploymentHistory([...newEmploymentHistory, newEntry]);
  };
  console.log("newEmploymentHistory", newEmploymentHistory);
  //

  //********************delete employment history********************//
  let deleteEmploymentHistoryArray: any[] = [];
  const [
    deleteEmploymentHistoryArrayState,
    setDeleteEmploymentHistoryArrayState,
  ] = useState(deleteEmploymentHistoryArray);

  const deleteEmploymentHistoryFromDatabase = (event: any) => {
    if (resumeData?.workingExperience) {
      //   console.log("Button id:", event.target.id);
      const id = parseInt(event.target.id);
      setDeleteEmploymentHistoryArrayState([
        ...deleteEmploymentHistoryArrayState,
        id,
      ]);

      resumeData.workingExperience = resumeData?.workingExperience.filter(
        (item: any) => item.experience_id !== id
      );
    }
  };
  console.log(
    "deleteEmploymentHistoryArrayState",
    deleteEmploymentHistoryArrayState
  );

  //**********************************handle education**************************************//

  const OnUpdateEducation = useMutation({
    mutationFn: async (data: { existingEducationSubmitted: any }) =>
      updateEducation(data.existingEducationSubmitted),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["resumeData"],
        exact: true,
      }),
  });

  const OnCreateEducation = useMutation({
    mutationFn: async (data: { newEducationSubmitted: any }) =>
      createEducation(data.newEducationSubmitted),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["resumeData"],
        exact: true,
      }),
  });

  const OnDeleteEducation = useMutation({
    mutationFn: async (data: { deletedEducationId: any }) =>
      deleteEducation(data.deletedEducationId),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["resumeData"],
        exact: true,
      }),
  });

  //**************delete education***************//

  let deleteEducationArray: any[] = [];
  const [deleteEducationArrayState, setDeleteEducationArrayState] =
    useState(deleteEducationArray);

  const deleteEducationFromDatabase = (event: any) => {
    if (resumeData?.education) {
      console.log("Button id:", event.target.id);
      const id = parseInt(event.target.id);
      setDeleteEducationArrayState([...deleteEducationArrayState, id]);

      resumeData.education = resumeData?.education.filter(
        (item: any) => item.education_id !== id
      );
    }
  };
  console.log("deleteEducationArrayState", deleteEducationArrayState);

  //******************************************handle skill**********************************************//
  const OnUpdateSkill = useMutation({
    mutationFn: async (data: { existingSkillSubmitted: any }) =>
      updateSkill(data.existingSkillSubmitted),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["resumeData"],
        exact: true,
      }),
  });

  const OnCreateSkill = useMutation({
    mutationFn: async (data: { newSkillSubmitted: any }) =>
      createSkill(data.newSkillSubmitted),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["resumeData"],
        exact: true,
      }),
  });

  const OnDeleteSkill = useMutation({
    mutationFn: async (data: { deletedSkillId: any }) =>
      deleteSkill(data.deletedSkillId),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["resumeData"],
        exact: true,
      }),
  });

  //**************delete skill***************//

  let deleteSkillArray: any[] = [];
  const [deleteSkillArrayState, setDeleteSkillArrayState] =
    useState(deleteSkillArray);

  const deleteSkillFromDatabase = (event: any) => {
    if (resumeData?.skill) {
      console.log("Button id:", event.target.id);
      const id = parseInt(event.target.id);
      setDeleteSkillArrayState([...deleteSkillArrayState, id]);

      resumeData.skill = resumeData?.skill.filter(
        (item: any) => item.skill_id !== id
      );
    }
  };
  console.log("deleteSkillArrayState", deleteSkillArrayState);

  //*****************************************handle reference*********************************************//

  const OnUpdateReference = useMutation({
    mutationFn: async (data: { existingReferenceSubmitted: any }) =>
      updateReference(data.existingReferenceSubmitted),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["resumeData"],
        exact: true,
      }),
  });

  const OnCreateReference = useMutation({
    mutationFn: async (data: { newReferenceSubmitted: any }) =>
      createReference(data.newReferenceSubmitted),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["resumeData"],
        exact: true,
      }),
  });

  const OnDeleteReference = useMutation({
    mutationFn: async (data: { deletedReferenceId: any }) =>
      deleteReference(data.deletedReferenceId),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["resumeData"],
        exact: true,
      }),
  });

  //**************delete reference***************//

  let deleteReferenceArray: any[] = [];
  const [deleteReferenceArrayState, setDeleteReferenceArrayState] =
    useState(deleteReferenceArray);

  const deleteReferenceFromDatabase = (event: any) => {
    if (resumeData?.reference) {
      console.log("Button id:", event.target.id);
      const id = parseInt(event.target.id);
      setDeleteReferenceArrayState([...deleteReferenceArrayState, id]);

      resumeData.reference = resumeData?.reference.filter(
        (item: any) => item.reference_id !== id
      );
    }
  };
  console.log("deleteReferenceArrayState", deleteReferenceArrayState);

  //**************************handle language********************************//

  const OnUpdateLanguage = useMutation({
    mutationFn: async (data: { existingLanguageSubmitted: any }) =>
      updateLanguage(data.existingLanguageSubmitted),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["resumeData"],
        exact: true,
      }),
  });

  const OnCreateLanguage = useMutation({
    mutationFn: async (data: { newLanguageSubmitted: any }) =>
      createLanguage(data.newLanguageSubmitted),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["resumeData"],
        exact: true,
      }),
  });

  const OnDeleteLanguage = useMutation({
    mutationFn: async (data: { deletedLanguageId: any }) =>
      deleteLanguage(data.deletedLanguageId),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["resumeData"],
        exact: true,
      }),
  });

  //**************delete language***************//

  let deleteLanguageArray: any[] = [];
  const [deleteLanguageArrayState, setDeleteLanguageArrayState] =
    useState(deleteLanguageArray);

  const deleteLanguageFromDatabase = (event: any) => {
    if (resumeData?.language) {
      console.log("Button id:", event.target.id);
      const id = parseInt(event.target.id);
      setDeleteLanguageArrayState([...deleteLanguageArrayState, id]);

      resumeData.language = resumeData?.language.filter(
        (item: any) => item.language_id !== id
      );
    }
  };
  console.log("deleteLanguageArrayState", deleteLanguageArrayState);

  //

  return (
    <div className={style.cvBuilderBigContainer}>
      {isAuthenticated ? (
        <>
          {resumeData &&
          resumeData?.workingExperience &&
          resumeData?.education &&
          resumeData?.reference &&
          resumeData?.language &&
          resumeData?.personalData.length > 0 &&
          resumeData?.personalData[0].user_id == user_id &&
          resumeData?.personalData[0].resume_id == resumeId ? 
         (
            <div>
              {showPreview == false ? (
                <div className={style.cvBuilderContainer}>
                  <div className={style.cvFormArea}>
                    {resumeData?.personalData[0].s3_file_path ? (
                      <Fab
                        onClick={showPreviewButton}
                        className={style.previewButton}
                        variant="extended"
                        color="primary"
                        aria-label="Preview CV"
                        sx={{ textTransform: "none" }}
                      >
                        Preview CV
                      </Fab>
                    ) : (
                      false
                    )}

                    <Container component="main" maxWidth="md">
                      <CssBaseline />
                      <Box
                        sx={{
                          // marginTop: 2,
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Box
                          component="form"
                          noValidate
                          onSubmit={handleSubmit}
                          sx={{ mt: 3 }}
                        >
                          <Grid
                            container
                            // spacing={2}
                            sx={{ mb: 4, textAlign: "center" }}
                          >
                            <Grid
                              item
                              xs={12}
                              sx={{
                                borderBottom: 2,
                                borderColor: "#2196f3",
                              }}
                            >
                              <TextField
                                // size="small"
                                variant="standard"
                                sx={{
                                  mb: 3,
                                }}
                                inputProps={{
                                  // min: 0,
                                  style: {
                                    textAlign: "center",
                                    fontSize: "20px",
                                  },
                                  maxLength: 32,
                                }}
                                name="title"
                                id="title"
                                defaultValue={
                                  resumeData?.personalData[0].title
                                    ? resumeData?.personalData[0].title
                                    : "[Untitled]"
                                }
                                label="Title"
                              />
                            </Grid>
                          </Grid>

                          {/* *******************************personal details******************************** */}
                          <Typography
                            component="h1"
                            variant="h6"
                            align="left"
                            sx={{
                              mb: 2,
                              fontWeight: "bold",
                            }}
                            className={style.cvBuilderSectionTitle}
                          >
                            Personal Details
                          </Typography>
                          <Grid container spacing={2} sx={{ mb: 4 }}>
                            <TextField
                              size="small"
                              value={resumeId}
                              name="resume_id"
                              sx={{ display: "none" }}
                            />
                            <Grid item xs={12} sm={6}>
                              <TextField
                                size="small"
                                name="firstName"
                                variant="filled"
                                defaultValue={
                                  resumeData?.personalData[0].first_name
                                }
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                size="small"
                                variant="filled"
                                defaultValue={
                                  resumeData?.personalData[0].last_name
                                }
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                size="small"
                                variant="filled"
                                defaultValue={resumeData?.personalData[0].email}
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                size="small"
                                variant="filled"
                                defaultValue={resumeData?.personalData[0].phone}
                                fullWidth
                                name="phone"
                                label="Phone"
                                type="phone"
                                id="phone"
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <TextField
                                size="small"
                                variant="filled"
                                defaultValue={
                                  resumeData?.personalData[0].target_job
                                }
                                fullWidth
                                name="targetPosition"
                                label="Target Position"
                                id="targetPosition"
                              />
                            </Grid>
                          </Grid>

                          {/* *******************************summary******************************** */}
                          <Typography
                            component="h1"
                            variant="h6"
                            align="left"
                            sx={{
                              mb: 2,
                              fontWeight: "bold",
                            }}
                            className={style.cvBuilderSectionTitle}
                          >
                            Summary
                          </Typography>
                          <Grid
                            container
                            spacing={2}
                            sx={{
                              mb: 4,
                            }}
                          >
                            <Grid item xs={12}>
                              <TextField
                                multiline
                                variant="filled"
                                name="summary"
                                label="Your professional summary here..."
                                value={summary}
                                fullWidth
                                id="summary"
                                rows={5}
                                onChange={OnHandleSummaryChange}
                              />

                              <AIsuggestion
                                content={summary}
                                handleChange={changeSummary}
                                aiApi={summaryDescriptionSuggestion}
                              />
                            </Grid>
                          </Grid>

                          <EmploymentHistory
                            workingExperience={resumeData?.workingExperience}
                            deleteEmploymentHistoryFromDatabase={
                              deleteEmploymentHistoryFromDatabase
                            }
                            resumeId={resumeData.personalData[0].resume_id}
                          />
                          <Education
                            education={resumeData?.education}
                            deleteEducationFromDatabase={
                              deleteEducationFromDatabase
                            }
                            resumeId={resumeData.personalData[0].resume_id}
                          />

                          <Skill
                            skill={resumeData?.skill}
                            deleteSkillFromDatabase={deleteSkillFromDatabase}
                            resumeId={resumeData.personalData[0].resume_id}
                          />

                          <Language
                            language={resumeData?.language}
                            deleteLanguageFromDatabase={
                              deleteLanguageFromDatabase
                            }
                            resumeId={resumeData.personalData[0].resume_id}
                          />

                          <Reference
                            reference={resumeData?.reference}
                            deleteReferenceFromDatabase={
                              deleteReferenceFromDatabase
                            }
                            resumeId={resumeData.personalData[0].resume_id}
                          />

                          <Typography
                            component="h1"
                            variant="h6"
                            align="left"
                            sx={{
                              mb: 2,
                              fontWeight: "bold",
                            }}
                            className={style.cvBuilderSectionTitle}
                          >
                            Templates
                          </Typography>

                          <div className={style.cvTemplateContainer}>
                            <Card
                              sx={{ maxWidth: 300, cursor: "pointer" }}
                              onClick={chooseTemplate1}
                              className={
                                resumeData.personalData[0].template ==
                                "cvTemplate1.docx"
                                  ? style.choseCvTemplate
                                  : ""
                              }
                            >
                              <CardMedia
                                sx={{
                                  padding: "1em 1em 1em 1em",
                                  objectFit: "contain",
                                  height: 420,
                                  width: 300,
                                }}
                                image={cvTemplate1}
                                title="cvTemplate1"
                              />
                            </Card>

                            <Card
                              sx={{ maxWidth: 300, cursor: "pointer" }}
                              onClick={chooseTemplate2}
                              className={
                                resumeData.personalData[0].template ==
                                "cvTemplate2.docx"
                                  ? style.choseCvTemplate
                                  : ""
                              }
                            >
                              <CardMedia
                                sx={{
                                  padding: "1em 1em 1em 1em",
                                  objectFit: "contain",
                                  height: 420,
                                  width: 300,
                                }}
                                image={cvTemplate2}
                                title="cvTemplate2"
                              />
                            </Card>
                          </div>

                          {/* ************************************Submission***********************************/}
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                              mt: 3,
                              mb: 2,
                              fontFamily: "Exo, sans-serif",
                              textTransform: "none",
                            }}
                            onClick={PreviewButtonAppear}
                            startIcon={<BackupSharpIcon />}
                          >
                            {Array.isArray(resumeData.personalData) &&
                            resumeData?.personalData[0].s3_file_path
                              ? "Save to Update"
                              : "Save to Preview"}
                          </Button>
                        </Box>
                      </Box>
                    </Container>
                  </div>

                  <div className={style.userButton}>
                    <div></div>
                    <UserMenu />
                  </div>
                  <a href="/jobSearch">
                    <img className={style.navLogo} src={logo} alt="Logo" />
                  </a>
                  <div className={style.cvPreviewArea}>
                    <div className={style.docViewerHalfContainer}>
                      <div className={style.docHalfViewer}>
                        {resumeData?.personalData[0].s3_file_path ? (
                          <>
                            <div className={style.halfPreviewNavContainer}>
                              <Typography
                                component="h1"
                                variant="h5"
                                align="left"
                                sx={{
                                  fontFamily: "Exo",
                                  fontWeight: "bold",
                                  color: "white",
                                }}
                              >
                                Preview
                              </Typography>

                              <>
                                <a
                                  href={
                                    resumeData?.personalData[0].s3_file_path
                                  }
                                  download="resume.docx"
                                >
                                  <Button
                                    startIcon={<DownloadIcon />}
                                    size="small"
                                    variant="contained"
                                    sx={{
                                      boxShadow: "none",
                                      textTransform: "none",
                                      justifyContent: "flex-start",
                                      fontFamily: "Montserrat, sans-serif",
                                    }}
                                  >
                                    Download DOCX
                                  </Button>
                                </a>
                              </>
                            </div>
                            <CvPreviewer
                              url={
                                resumeData
                                  && resumeData?.personalData[0].s3_file_path
                                  // : CVUrlRef.current
                              }
                              rerender={rerender}
                            />
                            {/* <CvPreviewer url={CVUrlRef.current}/> */}
                          </>
                        ) : (
                          <>
                            <div className={style.halfPreviewNavContainer}>
                              <Typography
                                component="h1"
                                variant="h5"
                                align="left"
                                sx={{
                                  fontFamily: "Exo",
                                  fontWeight: "bold",
                                  color: "white",
                                }}
                              >
                                Preview
                              </Typography>
                            </div>
                            <div className={style.emptyPreviewArea}>
                              <PreviewIcon
                                sx={{ fontSize: 150, color: "grey.400" }}
                              />
                              Save to Generate and Preview Resume
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                // below part is to show full width CV preview for mobile --> related to useState: showPreview

                <div className={style.docViewerFullContainer}>
                  <div className={style.fullPreviewNavContainer}>
                    <Button
                      variant="text"
                      onClick={backToEditorButton}
                      sx={{
                        textTransform: "none",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: 15,
                        alignItems: "center",
                      }}
                      startIcon={<ArrowBackIcon />}
                    >
                      <div className={style.halfBackButton}>Back to Editor</div>
                    </Button>
                    <Typography
                      component="h1"
                      variant="h5"
                      align="left"
                      sx={{
                        fontFamily: "Exo",
                        fontWeight: "bold",
                        color: "white",
                      }}
                      className={style.previewText}
                    >
                      Preview
                    </Typography>
                    <>
                      <a
                        href={resumeData?.personalData[0].s3_file_path}
                        download="resume.docx"
                      >
                        <Button
                          startIcon={<DownloadIcon />}
                          size="small"
                          variant="contained"
                          sx={{
                            boxShadow: "none",
                            textTransform: "none",
                            justifyContent: "flex-start",
                            fontFamily: "Montserrat, sans-serif",
                          }}
                        >
                          Download DOCX
                        </Button>
                      </a>
                    </>
                  </div>
                  <div className={style.docFullViewer}>
                    <CvPreviewer
                      url={
                        resumeData
                        && resumeData?.personalData[0].s3_file_path
                          // : CVUrlRef.current
                      }
                      rerender={rerender}
                    />
                    {/* <CvPreviewer url={CVUrlRef.current}/> */}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          )}
        </>
      ) : (
        <Typography
          component="h1"
          variant="h6"
          sx={{
            mt: 4,
            fontWeight: "bold",
          }}
        >
          Please{" "}
          <Link href="/login" underline="hover">
            login{" "}
          </Link>
          to access this page
        </Typography>
      )}
    </div>
  );
}
