import * as React from "react";

import style from "./CvBuilder.module.css";
import { Fab } from "@mui/material";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { createUser } from "../hooks/authAPI";

import { CircularProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DownloadIcon from "@mui/icons-material/Download";
import BackupSharpIcon from "@mui/icons-material/BackupSharp";

import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { changeEducationArrayDescription, OnHandleArrayDescriptionChange } from "./OnHandleArrayDescriptionChange";
import { AIsuggestion } from "./AIsuggestion";
import { educationDescriptionSuggestion } from "../hooks/aiApi";

type EducationProps = {
  education: Array<any>;
  resumeId: number;
  deleteEducationFromDatabase: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
};

export default function Education(props: EducationProps) {
  //**************add new education*******************/

  const [newEducation, setNewEducation]: any[] = useState([]);

  const addEducation = () => {
    const newEduEntry = { tmpEdu_id: Math.floor(Math.random() * 1000), value:'' };
    setNewEducation([...newEducation, newEduEntry]);
  };
  console.log("newEducation", newEducation);

  //**************delete education***************//

  const deleteNewEducation = (event: any) => {
    console.log("Button id:", event.target.id);
    const id = parseInt(event.target.id);
    setNewEducation(newEducation.filter((item: any) => item.tmpEdu_id !== id));
  };

  //******************handle Employment Description********************* */
  const [ educationDescription, setEducationDescription ] = useState<string[]>([])

  useEffect(() => {
    if(props.education){
      setEducationDescription(props.education.map((item:any)=>item.description))
    }
  },[props])

  const OnHandleEducationDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index:number, educationDescription:Array<string>, setEducationDescription:Function) => {
    OnHandleArrayDescriptionChange(event, index, educationDescription,setEducationDescription)
  }

  const changeEducationDescription = (aiResult:string, index:number) => {setEducationDescription(
    educationDescription.map((element, i) => {return i === index ? aiResult : element})
  )}

  //******************Handle new added employment description******************* */
  const OnHandleNewEducationDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, tmpEdu_id:number)=>{
    const updateNewEducationDescription = changeEducationArrayDescription (newEducation, event.currentTarget.value, tmpEdu_id)
    setNewEducation(updateNewEducationDescription)
  }

  const changeNewEducationDescription = (aiResult: string, indexFromAI:number) => {
    const updateNewEducationDescription = changeEducationArrayDescription (newEducation, aiResult, indexFromAI)
    setNewEducation (updateNewEducationDescription)
  }

  return (
    <div>
      {/* ************************************Education***********************************/}

      <Typography
        component="h1"
        variant="h6"
        align="left"
        sx={{
          mb: 2,
          fontWeight: "bold",
        }}
        className={style.cvBuilderSectionTitle}
      >
        Education
      </Typography>
      {props.education.map((item: any, index: number) => (
        <Accordion key={item.education_id.toString()} sx={{ mb: 1, p: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            {item.education_title ? item.education_title : "Untitled"}
          </AccordionSummary>
          <AccordionDetails>
            <Grid
                          container
                          //*******set key for each component mapped******
                          key={item.education_id}
                          sx={{
                        
                          }}
                        >
            <TextField
              size="small"
              value={item.education_id.toString()}
              name="education_id"
              sx={{ display: "none" }}
            />
            <TextField
              size="small"
              value={item.resume_id.toString()}
              name="resume_id"
              sx={{ display: "none" }}
            />

            <Grid
              item
              xs={12}
              
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                name="educationTitle"
                defaultValue={item.education_title}
                fullWidth
                id="educationTitle"
                label="Education"
              />
            </Grid>
            <Grid
              item
              xs={12}
              
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                fullWidth
                defaultValue={item.school}
                id="school"
                label="School"
                name="school"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                fullWidth
                defaultValue={item.start_date}
                id="eduStartDate"
                label="Start Date"
                name="eduStartDate"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                fullWidth
                defaultValue={item.end_date}
                name="eduEndDate"
                label="End Date"
                id="eduEndDate"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: "10px",
              }}
            >
                            <TextField
                              multiline
                              variant="filled"
                              name="eduDescription"
                              // defaultValue={item.description}
                              value={educationDescription[index]}
                              fullWidth
                              id="eduDescription"
                              label="Description"
                              rows={5}
                              onChange={(event)=>OnHandleEducationDescriptionChange(event,index,educationDescription,setEducationDescription)}
                            />

                            <AIsuggestion 
                              content={educationDescription[index]}
                              handleChange={changeEducationDescription}
                              aiApi={educationDescriptionSuggestion}
                              index={index}
                            />
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{
                alignItems: "center",
                // justifyContent: "space-between",
                justifyContent: "center",
                ml: 1,
              }}
            >
              {/* <Typography
                                  component="h1"
                                  variant="subtitle1"
                                  align="left"
                                  sx={{ fontWeight: 'bold'}}
                              >
                                  {item.job_title} at {item.company}
                              </Typography> */}

              <Tooltip id={item.education_id.toString()} title="Delete">
                <IconButton
                  onClick={props.deleteEducationFromDatabase}
                  id={item.education_id.toString()}
                  color="error"
                >
                  <DeleteOutlineRoundedIcon
                    id={item.education_id.toString()}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
      {newEducation.map((item: any) => (
        <Accordion
          key={item.tmpEdu_id.toString()}
          sx={{ mb: 1, p: 1 }}
          defaultExpanded
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            {item.education_title ? item.education_title : "Untitled"}
          </AccordionSummary>
          <AccordionDetails>
            <Grid
                          container
                          //*******set key for each component mapped******
                          key={item.tmpEdu_id}
                          sx={{
                         
                          }}
                        >
            <TextField
              size="small"
              value={props.resumeId.toString()}
              name="resume_id"
              sx={{ display: "none" }}
            />

            <Grid
              item
              xs={12}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                name="educationTitle"
                fullWidth
                id="educationTitle"
                label="Education"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                fullWidth
                id="school"
                label="School"
                name="school"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                fullWidth
                id="eduStartDate"
                label="Start Date"
                name="eduStartDate"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                fullWidth
                name="eduEndDate"
                label="End Date"
                id="eduEndDate"
              />
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                multiline
                variant="filled"
                name="eduDescription"
                fullWidth
                id="eduDescription"
                label="Description"
                rows={5}
                value={item.value}
                onChange={(event)=>OnHandleNewEducationDescriptionChange(event, item.tmpEdu_id)}
              />
              <AIsuggestion 
                content={item.value}
                handleChange={changeNewEducationDescription}
                aiApi={educationDescriptionSuggestion}
                index={item.tmpEdu_id}
              /> 
            </Grid>
            <Grid
              item
              xs={12}
              container
              sx={{
                justifyContent: "center",
              }}
            >
              <Tooltip id={item.tmpEdu_id.toString()} title="Delete">
                <IconButton
                  onClick={deleteNewEducation}
                  id={item.tmpEdu_id.toString()}
                  color="error"
                >
                  <DeleteOutlineRoundedIcon
                    id={item.tmpEdu_id.toString()}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}

      <Button onClick={addEducation} sx={{ textTransform: "none", mb: 4 }}>
        + Add education
      </Button>
    </div>
  );
}
