import { FormEventHandler, useState } from "react";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import { TextField } from "./TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { createUser } from "../hooks/authAPI";
import { jwtDecode } from "jwt-decode";
import { CircularProgress } from "@mui/material";

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function RegisterPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState<boolean | null>(null);

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    const form = event.target as HTMLFormElement;
    const formData = new FormData(event.currentTarget);

    // validate password match
    const password = formData.get("password")!.toString();
    const repeatPassword = formData.get("repeatPassword")!.toString();
    if (password.length > 0 && password !== repeatPassword) {
      setIsPasswordMatch(false);
      return;
    } else {
      setIsPasswordMatch(null);
    }

    // stop here if form is invalid
    if (!form.checkValidity()) {
      const invalidInput = form.querySelectorAll<HTMLInputElement>(":invalid");
      invalidInput[0].focus();
      return;
    }

    const firstName = formData.get("firstName")!.toString();
    const lastName = formData.get("lastName")!.toString();
    const email = formData.get("email")!.toString();
    const username = formData.get("username")!.toString();

    setIsLoading(true);
    const success = await createUser(
      firstName,
      lastName,
      email,
      username,
      password
    );

    console.log("sign up success:", success);
    if (success) {
      let decoded: { user_id: number; username: string } = jwtDecode(
        localStorage.getItem("token")!
      );

      console.log("decoded after login: ", decoded);

      // dispatch(login({username:decoded.username, user_id:decoded.user_id}))
      // navigate('/')
      window.location.href = "/jobSearch";
    } else {
      // Error Handling, using react-toast is one possibility
      console.log("sign up failed");
      setIsLoading(false);
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h1"
            variant="h5"
            sx={{
              mb: 1,
              fontWeight: "bold",
              fontFamily: "Exo, sans-serif",
            }}
          >
            Create account
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  onInvalid={(e) => {
                    console.debug(e);
                  }}
                  valueMissingMessage="Please enter your first name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  required
                  valueMissingMessage="Please enter your last name"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="username"
                  label="Username"
                  name="username"
                  autoComplete="username"
                  required
                  valueMissingMessage="Please enter a username"
                  inputProps={{ minLength: 3 }}
                  tooShortMessage="Username must be at least 3 characters long"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="email"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  inputMode="email"
                  required
                  valueMissingMessage="Please enter an email address"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  required
                  valueMissingMessage="Please enter a password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  name="repeatPassword"
                  label="Repeat Password"
                  type="password"
                  id="repeatPassword"
                  required
                  valueMissingMessage="Please repeat your password"
                  error={
                    isPasswordMatch !== null ? !isPasswordMatch : undefined
                  }
                  helperText={
                    isPasswordMatch !== null && !isPasswordMatch
                      ? "Passwords do not match"
                      : undefined
                  }
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, fontFamily: "Exo, sans-serif", textTransform: "none"}}
            >
              {isLoading !== true ? (
                "Register"
              ) : (
                <CircularProgress size="25px" color="inherit" />
              )}
            </Button>
            <Grid container justifyContent="center">
              <Grid item>
                <Link href="/login" variant="body2">
                  Already have an account? Login Here
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
