const source = process.env.REACT_APP_API_SERVER

// export async function GetJobListByCategory(category:string, cb:Function){
export async function GenerateCv(cvInformation:any){
  const res = await fetch(`${source}/generateCvDocx`, {
    method:"POST",
    headers: {
      "Content-Type":"application/json"
    },
    body: JSON.stringify(cvInformation)
  })
  const resp = await res.json()
  // return cb(resp)
  return resp
}