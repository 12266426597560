import * as React from "react";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import style from "./CvBuilder.module.css";
import { Fab } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Accordion, { AccordionSlots } from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';




type LanguageProps = {
  language: Array<any>;
  resumeId: number;
  deleteLanguageFromDatabase: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
};

export default function Language(props: LanguageProps) {





  //**************add new language*******************/

  const [newLanguage, setNewLanguage]: any[] = useState([]);

  const addLanguage = () => {
    const newLanEntry = { tmpLan_id: Math.floor(Math.random() * 1000) };
    setNewLanguage([...newLanguage, newLanEntry]);
  };
  console.log("newLanguage", newLanguage);

  //**************delete new language***************//

  const deleteNewLanguage = (event: any) => {
    console.log("Button id:", event.target.id);
    const id = parseInt(event.target.id);
    setNewLanguage(newLanguage.filter((item: any) => item.tmpLan_id !== id));
  };

  return (
    <div>
      <Typography
        component="h1"
        variant="h6"
        align="left"
        sx={{
          mb: 2,
          fontWeight: "bold",
        }}
        className={style.cvBuilderSectionTitle}
      >
        Language
      </Typography>
      {props.language.map((item: any, index: number) => (
        <Accordion key={item.language_id.toString()} sx={{ mb: 1, p: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            {item.language? item.language : "Unspecified"}
          </AccordionSummary>
          <AccordionDetails>
            <Grid
                container
                key={item.language_id.toString()}
                sx={{
                }}
            >

                <TextField
                  size="small"
                  value={item.language_id.toString()}
                  name="language_id"
                  sx={{ display: "none" }}
                />
                <TextField
                  size="small"
                  value={item.resume_id.toString()}
                  name="resume_id"
                  sx={{ display: "none" }}
                />

                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    padding: "10px",
                  }}
                >
                  <TextField
                    size="small"
                    variant="filled"
                    name="language"
                    defaultValue={item.language}
                    fullWidth
                    id="language"
                    label="Language"
                    sx={{ bb: 0 }}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    padding: "10px",
                  }}
                >
                  <FormControl variant="filled" size="small" fullWidth sx={{ minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-filled-label">Level</InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="level"
                      defaultValue={item.level.split("_").join(" ")}
                      // onChange={handleChange}
                    >
                      <MenuItem value={"Native"}>Native</MenuItem>
                      <MenuItem value={"Professional Proficiency"}>Professional Proficiency</MenuItem>
                      <MenuItem value={"Working Proficiency"}>Working Proficiency</MenuItem>
                      <MenuItem value={"Intermediate"}>Intermediate</MenuItem>
                      <MenuItem value={"Beginner"}>Beginner</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                
                <Grid
                  item
                  xs={12}
                  container
                  sx={{
                    alignItems: "center",
                    // justifyContent: "space-between",
                    justifyContent: "center",
                    ml: 1,
                  }}
                >
                  <Tooltip id={item.language_id.toString()} title="Delete">
                    <IconButton
                      onClick={props.deleteLanguageFromDatabase}
                      id={item.language_id.toString()}
                      color="error"
                    >
                      <DeleteOutlineRoundedIcon
                        id={item.language_id.toString()}
                        fontSize="small"
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
            </Grid> 
          </AccordionDetails>
        </Accordion>
      ))}
      {newLanguage.map((item: any) => (
        <Accordion
          key={item.tmpLan_id.toString()}
          sx={{ mb: 1, p: 1 }}
          defaultExpanded
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            Untitled
          </AccordionSummary>
          <AccordionDetails>
            <Grid
                          container
                          //*******set key for each component mapped******
                          key={item.tmpLan_id}
                          sx={{
                            
                          }}
                        >
            <TextField
              size="small"
              value={props.resumeId.toString()}
              name="resume_id"
              sx={{ display: "none" }}
            />

            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                padding: "10px",
              }}
            >
              <TextField
                size="small"
                variant="filled"
                name="language"
                fullWidth
                id="language"
                label="Language"
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                padding: "10px",
              }}
            >
              <FormControl variant="filled" size="small" fullWidth sx={{ minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-filled-label">Level</InputLabel>
                    <Select
                      labelId="demo-simple-select-filled-label"
                      id="demo-simple-select-filled"
                      name="level"
                      // defaultValue={item.level.split("_").join(" ")}
                      // onChange={handleChange}
                    >
                      <MenuItem value={"Native"}>Native</MenuItem>
                      <MenuItem value={"Professional Proficiency"}>Professional Proficiency</MenuItem>
                      <MenuItem value={"Working Proficiency"}>Working Proficiency</MenuItem>
                      <MenuItem value={"Intermediate"}>Intermediate</MenuItem>
                      <MenuItem value={"Beginner"}>Beginner</MenuItem>
                    </Select>
                  </FormControl>
            </Grid>
            
          
          
            <Grid
              item
              xs={12}
              container
              sx={{
                justifyContent: "center",
              }}
            >
              <Tooltip id={item.tmpLan_id.toString()} title="Delete">
                <IconButton
                  onClick={deleteNewLanguage}
                  id={item.tmpLan_id.toString()}
                  color="error"
                >
                  <DeleteOutlineRoundedIcon
                    id={item.tmpLan_id.toString()}
                    fontSize="small"
                  />
                </IconButton>
              </Tooltip>
            </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}

      <Button
        onClick={addLanguage}
        sx={{ textTransform: "none", mb: 4 }}
      >
        + Add Language
      </Button>
    </div>
  );
}
