const source = process.env.REACT_APP_API_SERVER

export async function localLogin(emailOrUsername: string, password: string){
    const res = await fetch(`${source}/login`,{
        method: "POST",
        headers: {
            "Content-Type":'application/json'
        },
        body: JSON.stringify({
            emailOrUsername,
            password
        })
    })

    const result = await res.json();
    if(result.token){
        localStorage.setItem('token', result.token)
        return true
    } else {
        return false
    }
}

export async function createUser(firstName:string, lastName:string, email: string, username: string, password: string){
    const res = await fetch(`${source}/createUser`,{
        method: "POST",
        headers: {
            "Content-Type":'application/json'
        },
        body: JSON.stringify({
            firstName,
            lastName,
            username,
            email,
            password
        })
    })

    const result = await res.json();
    if(res.status === 200){
        localStorage.setItem('token', result.token)
        return true
    } else {
        return false
    }
}