
import * as React from 'react';

import style from "./CvBuilder.module.css";
import { Fab } from "@mui/material";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { createUser } from "../hooks/authAPI";

import { CircularProgress } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import BackupSharpIcon from '@mui/icons-material/BackupSharp';

import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Accordion, { AccordionSlots } from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { changeEmploymentArrayDescription, OnHandleArrayDescriptionChange } from './OnHandleArrayDescriptionChange';
import { AIsuggestion } from './AIsuggestion';
import { employmentDescriptionSuggestion } from '../hooks/aiApi';


type EmploymentHistoryProps = {
    workingExperience: Array<any>
    resumeId: number
    deleteEmploymentHistoryFromDatabase: (event: React.MouseEvent<HTMLButtonElement>) => void
}
    

export default function EmploymentHistory(props: EmploymentHistoryProps) { 
    
    //**************add new employment history*******************/
        const [newEmploymentHistory, setNewEmploymentHistory]: any[] = useState([]);

        const addEmploymentHistory = () => {
            const newEntry = { tmpEmp_id: Math.floor(Math.random() * 1000) , value: ""};
            setNewEmploymentHistory([...newEmploymentHistory, newEntry]);
        };
        console.log("newEmploymentHistory", newEmploymentHistory);
        //
    

     //********************delete employment history********************//
    const deleteNewEmploymentHistory = (event: any) => {
        // console.log("Button id:", event.target.id);
        const id = parseInt(event.target.id);
        setNewEmploymentHistory(
          newEmploymentHistory.filter((item: any) => item.tmpEmp_id !== id)
        );
      };
    
      //******************handle existing Employment Description********************* */
      const [ employmentDescription, setEmploymentDescription ] = useState<string[]>([])
      const OnHandleEmploymentDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index:number, employmentDescription:Array<string>, setEmploymentDescription:Function) => {
        OnHandleArrayDescriptionChange(event, index, employmentDescription,setEmploymentDescription)
      }
      const changeEmploymentDescription = (aiResult:string,index:number) => {setEmploymentDescription(
        employmentDescription.map((element, i) => {return i === index ? aiResult : element})
      )}

      useEffect(()=>{
        if (props.workingExperience){
          setEmploymentDescription(props.workingExperience.map((item:any)=>item.description))
        }
      },[props.workingExperience])

      //******************Handle new added employment description******************* */

      const OnHandleNewEmploymentDescriptionChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, tmpEmp_id:number) => {
        const updateNewEmploymentDescription = changeEmploymentArrayDescription (newEmploymentHistory, event.currentTarget.value, tmpEmp_id)
        setNewEmploymentHistory(updateNewEmploymentDescription)
      }

      const changeNewEmploymentDescription = (aiResult: string, indexFromAI:number) => {
        const updateNewEmploymentDescription = changeEmploymentArrayDescription (newEmploymentHistory, aiResult, indexFromAI)
        setNewEmploymentHistory(updateNewEmploymentDescription)
      }
  
    return (
        <div>
             {/* *******************************employment history******************************** */}
             <Typography
                          component="h1"
                          variant="h6"
                          align="left"
                          sx={{
                            mb: 2,
                            fontWeight: "bold",
                          }}
                          className={style.cvBuilderSectionTitle}
                        >
                          Employment History
                        </Typography>
                        {props.workingExperience.map((item: any, index:number) => (
                          
                          <Accordion key={item.experience_id.toString()} sx={{mb:1, p:1}}>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1-content"
                              id="panel1-header"
                            >
                              {item.job_title? item.job_title : "Untitled"} 
                            </AccordionSummary>
                            <AccordionDetails>
                            <Grid
                          container
                          //*******set key for each component mapped******
                          key={item.experience_id}
                          sx={{
                          }}
                        >

                             <TextField
                            size="small"
                            value={item.experience_id.toString()}
                            name="experience_id"
                            sx={{ display: "none" }}
                          />
                          <TextField
                            size="small"
                            value={item.resume_id.toString()}
                            name="resume_id"
                            sx={{ display: "none" }}
                          />
                          
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              padding: "10px",
                            }}
                          >
                            <TextField
                              size="small"
                              variant="filled"
                              name="jobTitle"
                              defaultValue={item.job_title}
                              fullWidth
                              id="jobTitle"
                              label="Job Title"
                              sx={{bb:0}}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              padding: "10px",
                            }}
                          >
                            <TextField
                              size="small"
                              variant="filled"
                              fullWidth
                              defaultValue={item.company}
                              id="company"
                              label="Company"
                              name="company"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              padding: "10px",
                            }}
                          >
                            <TextField
                              size="small"
                              variant="filled"
                              fullWidth
                              defaultValue={item.start_date}
                              id="startDate"
                              label="Start Date"
                              name="startDate"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              padding: "10px",
                            }}
                          >
                            <TextField
                              size="small"
                              variant="filled"
                              fullWidth
                              defaultValue={item.end_date}
                              name="endDate"
                              label="End Date"
                              id="endDate"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              padding: "10px",
                            }}
                          >
                                        <TextField
                                        multiline
                                        variant="filled"
                                        name="jobDescription"
                                        // defaultValue={item.description}
                                        value={employmentDescription[index]}
                                        fullWidth
                                        id="jobDescription"
                                        label="Job Description"
                                        rows={5}
                                        onChange={(event)=>OnHandleEmploymentDescriptionChange(event,index,employmentDescription,setEmploymentDescription)}
                                        />

                                        <AIsuggestion 
                                          content={employmentDescription[index]}
                                          handleChange={changeEmploymentDescription}
                                          aiApi={employmentDescriptionSuggestion}
                                          index={index}
                                        /> 

                          </Grid>
                          <Grid
                            item
                            xs={12}
                            container
                            sx={{
                              alignItems: "center",
                              // justifyContent: "space-between",
                              justifyContent: "center",
                              ml: 1,
                            }}
                          >
                
                            <Tooltip
                              id={item.experience_id.toString()}
                              title="Delete"
                            >
                              <IconButton
                                onClick={props.deleteEmploymentHistoryFromDatabase}
                                id={item.experience_id.toString()}
                                color="error"
                              >
                                <DeleteOutlineRoundedIcon
                                  id={item.experience_id.toString()}
                                  fontSize="small"
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                           </Grid> 
                            
                            </AccordionDetails>
                          </Accordion>

                          
                        
                      ))}
                      {newEmploymentHistory.map((item: any) => (
                           <Accordion key={item.tmpEmp_id.toString()} sx={{mb:1, p:1}} defaultExpanded>
                           <AccordionSummary
                             expandIcon={<ExpandMoreIcon />}
                             aria-controls="panel1-content"
                             id="panel1-header"
                           >
                              Untitled
                           </AccordionSummary>
                           <AccordionDetails>
                        <Grid
                          container
                          //*******set key for each component mapped******
                          key={item.tmpEmp_id}
                          sx={{
                            // mb: 2,
                            // border: "1px solid",
                            // borderColor: "grey.300",
                            // borderRadius: "5px",
                            // padding: "10px",
                          }}
                        >
                          <TextField
                            size="small"
                            value={props.resumeId.toString()}
                            name="resume_id"
                            sx={{ display: "none" }}
                          />
                     
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              padding: "10px",
                            }}
                          >
                            <TextField
                              size="small"
                              variant="filled"
                              name="jobTitle"
                              fullWidth
                              id="jobTitle"
                              label="Job Title"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              padding: "10px",
                            }}
                          >
                            <TextField
                              size="small"
                              variant="filled"
                              fullWidth
                              id="company"
                              label="Company"
                              name="company"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              padding: "10px",
                            }}
                          >
                            <TextField
                              size="small"
                              variant="filled"
                              fullWidth
                              id="startDate"
                              label="Start Date"
                              name="startDate"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            sx={{
                              padding: "10px",
                            }}
                          >
                            <TextField
                              size="small"
                              variant="filled"
                              fullWidth
                              name="endDate"
                              label="End Date"
                              id="endDate"
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sx={{
                              padding: "10px",
                            }}
                          >
                            <TextField
                              multiline
                              variant="filled"
                              name="jobDescription"
                              fullWidth
                              id="jobDescription"
                              label="Job Description"
                              rows={5}
                              value={item.value}
                              onChange={(event)=>OnHandleNewEmploymentDescriptionChange(event,item.tmpEmp_id)}
                            />
                            <AIsuggestion 
                              content={item.value}
                              handleChange={changeNewEmploymentDescription}
                              aiApi={employmentDescriptionSuggestion}
                              index={item.tmpEmp_id}
                            /> 
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            container
                            sx={{
                              justifyContent: "center",
                            }}
                          >
                            <Tooltip
                              id={item.tmpEmp_id.toString()}
                              title="Delete"
                            >
                              <IconButton
                                onClick={deleteNewEmploymentHistory}
                                id={item.tmpEmp_id.toString()}
                                color="error"
                              >
                                <DeleteOutlineRoundedIcon
                                  id={item.tmpEmp_id.toString()}
                                  fontSize="small"
                                />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        </AccordionDetails>
                        </Accordion>
                      ))}

                      <Button
                        onClick={addEmploymentHistory}
                        sx={{ textTransform: "none", mb: 4 }}
                      >
                        + Add employment History
                      </Button>


        
        </div>
    )
}