import { useState } from 'react';
import Box from '@mui/material/Box';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';

interface State extends SnackbarOrigin {
  open: boolean;
}

export type TriggerSnackbar = {
    error: boolean
}

export default function PositionedSnackbar(props:TriggerSnackbar) {
  const [state, setState] = useState<State>({
    open: false,
    vertical: 'top',
    horizontal: 'center',
  });
  const { vertical, horizontal, open } = state;

    if (props.error) {
        setState({ ...state, open: true });
    }

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        message="AI Analysis Failed. Please try again."
        key={vertical + horizontal}
      />
    </Box>
  );
}