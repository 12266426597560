import { useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import style from "./ResumeCard.module.css";

import { Button, Link, SelectChangeEvent, TextField } from "@mui/material";
import Divider from '@mui/material/Divider';

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import {
  Unstable_NumberInput as BaseNumberInput,
  NumberInputProps,
  numberInputClasses,
} from '@mui/base/Unstable_NumberInput';
import { styled } from '@mui/system';
import Grow from '@mui/material/Grow';

import { deleteResume } from "../hooks/dashboardAPI";
import * as React from "react";

import resume from "../img/resume6.png";
import { aiGenerateCoverLetterFromCv, tailorMadeCVFromAI } from "../hooks/aiApi";
import { RootState } from "../store";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import PositionedSnackbar from "./PositionedSnackbar";

type ResumeCardProps = {
  resumeOpen: boolean;
  resumeId: number;
  resumeTitle: string;
  s3FilePath: string;
};



export default function ResumeCard(props: ResumeCardProps) {
 
  const navigate = useNavigate();

  const theme = useTheme();

  //=====================================================delete resume=====================================================//
  const queryClient = useQueryClient();
  const OnDeleteResume = useMutation({
    mutationFn: async (data: { resumeId: number }) =>
      deleteResume(data.resumeId),
    onSuccess: () => {
      console.log("onSuccess");
      queryClient.invalidateQueries({
        queryKey: ["resumeList"],
        exact: true,
      });
    },
  });

  const handleDelete = async () => {
    try {
      await OnDeleteResume.mutate({ resumeId: props.resumeId });
      handleClose()
    } catch (error) {
      console.error("An error occurred during deletion:", error);
    }
  };

  //======================================================================================================================//

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // const [rerender, setReRender] = React.useState<Boolean>(false)

  const isAuthenticated = useSelector<RootState>(
    (state) => state.auth.isAuthenticated
  );

  let user_id: number | null = null;
  if (isAuthenticated) {
    let decoded: { user_id: number; username: string } = jwtDecode(
      localStorage.getItem("token")!
    );
    user_id = decoded.user_id;
  }

  //=================================================generate cover letter=================================================//
  const [ openGenerateCoverLetterDialogue, setOpenGenerateCoverLetterDialogue ] = React.useState<boolean>(false)
  const handleOpenGenerateCoverLetterDialogue = () => {setOpenGenerateCoverLetterDialogue(true)}
  const handleCloseGenerateCoverLetterDialogue = () => {setOpenGenerateCoverLetterDialogue(false)}

  const [ coverLetterJobTitle, setCoverLetterJobTitle ] = React.useState<string>('')
  const [ coverLetterJobCompany, setCoverLetterJobCompany ] = React.useState<string>('')
  const [ coverLetterJobRequirement, setCoverLetterJobRequirement ] = React.useState<string>('')
  const [ coverLetterEmployerName, setCoverLetterEmployerName ] = React.useState<string>('')


 

  const [generatingCoverLetter, setGeneratingCoverLetter] = React.useState(false);

  // const handleDoneGenerating = () => {
  //   setGeneratingCoverLetter(false);
  // };
  // const handleGenerating = () => {
  //   setGeneratingCoverLetter(true);
  // };

  const generateCoverLetter = async () => {
    // setShowSkeleton(true)
    setGeneratingCoverLetter(true);
    setOpenGenerateCoverLetterDialogue(false)
    const coverLetterFromCvObject = await aiGenerateCoverLetterFromCv(user_id!, props.resumeId, coverLetterJobTitle, coverLetterJobCompany, coverLetterJobRequirement, coverLetterEmployerName)
    const coverLetterId = coverLetterFromCvObject.coverLetterFromCvId.coverLetterId[0].id
    navigate(`/coverLetterBuilder/${coverLetterId}`)
    setGeneratingCoverLetter(false);
  }

  //======================================================================================================================//

  const [ triggerAIErrorMessage, setTriggerAIErrorMessage ] = React.useState(false)

  //=================================================AI Tailor-Made for Job=================================================//
  const [ startAiTailorMadeCV, setStartAiTailorMadeCV ] = React.useState<boolean>(false)
  const handleClickOpenAiTailorMadeCVDialogue = () => {setStartAiTailorMadeCV(true)}
  const handleClickCloseAiTailorMadeCVDialogue = () => {setStartAiTailorMadeCV(false)}

  const [ jobTitle, setJobTitle ] = React.useState<string>('')
  const [ jobCompany, setJobCompany ] = React.useState<string>('')
  const [ jobRequirement, setJobRequirement ] = React.useState<string>('')

  const [ tailorMadeCvId, setTailorMadeCvId ] = React.useState<string>('')
  const [ generatingCV, setGeneratingCV ] = React.useState(false)
  const handleSubmit = async() => {
    console.log("Start AI Analysis....")
    setGeneratingCV(true)
    setStartAiTailorMadeCV(false)
    const aiResult = await tailorMadeCVFromAI(props.resumeId,jobTitle,jobCompany,jobRequirement,user_id!)
    const cvId = aiResult.newResumeId
    setTailorMadeCvId(cvId)
    setGeneratingCV(false)
  }
  React.useEffect(()=>{
    if (tailorMadeCvId == undefined ) {
      setTriggerAIErrorMessage(true)
    }
    if (tailorMadeCvId) navigate(`/cvBuilder/${tailorMadeCvId}`)
  },[tailorMadeCvId])


  //==================================================Download DOCX====================================================================//

  // const handleDownloadResume = () => {
  //   console.log("Downloading resume...")
  //   location.href(props.s3FilePath)
  // }

  //======================================================================================================================//

  return (

    <>
    <Grow in={props.resumeOpen}>
      <Card
        className={style.resumeCard}
        sx={{ display: "flex", flex: "wrap", p: 3 }}
      >
        <CardMedia
          component="img"
          sx={{ width: 200, cursor: "pointer", mr:2, p:4 }}
          image={resume}
          alt="Resume picture"
          onClick={() => navigate(`/cvBuilder/${props.resumeId}`)}
        />

        <Divider className={style.verticalDivider} orientation="vertical" variant="middle" sx={{bgcolor:'grey.700'}} flexItem />

        <Box sx={{ display: "flex", flexDirection: "column", width: 220, ml:2 }}>
          <CardContent
            sx={{
              // flex: "1 0 auto",
              flexDirection: "column",
              pl: 1.2,
              mb: 1,
              // height: 80,
            }}
          >
            <Typography
              component="div"
              variant="h6"
              onClick={() => navigate(`/cvBuilder/${props.resumeId}`)}
              sx={{ cursor: "pointer", width: "100%", fontFamily: "Exo" }}
            >
              {props.resumeTitle ? props.resumeTitle : "[Untitled]"}
            </Typography>
            {/* <Typography
              variant="subtitle2"
              color="text.secondary"
              component="div"
            >
              resume id: {props.resumeId}
            </Typography> */}
          </CardContent>
          <Box
            sx={{
              display: "flex",
              // alignItems: "center",
              flexDirection: "column",
              // justifyContent: "flex-start",
            }}
          >
            <Button
              sx={{
                textTransform: "none",
                justifyContent: "flex-start",
                fontFamily: "Montserrat, sans-serif",
                gap: 1,
              }}
              onClick={handleClickOpenAiTailorMadeCVDialogue}
            >
              <TipsAndUpdatesIcon />
              AI Tailor-Made for Job
            </Button>
            <Dialog maxWidth='xl' open={startAiTailorMadeCV} onClose={handleClickCloseAiTailorMadeCVDialogue}>
              <DialogTitle sx={{fontFamily:"Exo"}}>Please input the following information for AI Analysis: </DialogTitle>
              <DialogContent className={style.aiTailorMadeCVDialogue} dividers>
                <TextField className={style.aiTailorMadeCVDialogueTextfield} value={jobTitle} onChange={(e)=>setJobTitle(e.target.value)} name="jobTitle" label="Target Job Title" type="text" />
                <TextField className={style.aiTailorMadeCVDialogueTextfield} value={jobCompany} onChange={(e)=>setJobCompany(e.target.value)} name="jobCompany" label="Company Name of Target Job" type="text" />
                <TextField className={style.aiTailorMadeCVDialogueTextfield} value={jobRequirement} onChange={(e)=>setJobRequirement(e.target.value)} name="jobRequirement" label="Requirement of Target Job" type="text" multiline minRows={5} maxRows={15} />
                <div className={style.aiTailorMadeCVDialogueButtonSection}>
                  <Button sx={{textTransform:'none'}} onClick={handleSubmit}>Proceed AI Analysis</Button>
                  <Button sx={{textTransform:'none'}} onClick={handleClickCloseAiTailorMadeCVDialogue}>Cancel</Button>
                </div>
              </DialogContent>

            </Dialog>

            <Button
              sx={{
                textTransform: "none",
                gap: 1,
                justifyContent: "flex-start",
                fontFamily: "Montserrat, sans-serif",
              }}
              onClick={handleOpenGenerateCoverLetterDialogue}
            >
              <HistoryEduIcon /> Generate Cover Letter
            </Button>
            <Dialog open={openGenerateCoverLetterDialogue} onClose={handleCloseGenerateCoverLetterDialogue}>
              <DialogTitle sx={{fontFamily:"Exo"}}>Please input job details for AI Cover Letter Generation: </DialogTitle>
            
              <DialogContent  dividers>
               
                
                        <Grid
                          container
                          spacing={2}
                          sx={{ }}
                        >
                          <Grid item xs={12} >
                             <TextField 
                             fullWidth 
                             size="small" 
                             value={coverLetterJobTitle} 
                             onChange={(e)=>setCoverLetterJobTitle(e.target.value)} 
                             
                             label="Target Job Title" 
                             type="text" 
                             autoFocus
                             />
                             
                          </Grid>
                           <Grid item xs={12} sm={6}>
                            <TextField
                              size="small"
                              value={coverLetterEmployerName} 
                              onChange={(e)=>setCoverLetterEmployerName(e.target.value)}
                              fullWidth
                              id="firstName"
                              label="Employer Name"
                              
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              size="small"
                              value={coverLetterJobCompany} onChange={(e)=>setCoverLetterJobCompany(e.target.value)}
                              fullWidth
                              id="firstName"
                              label="Target Company"
                              
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField 
                            fullWidth 
                            value={coverLetterJobRequirement} 
                            onChange={(e)=>setCoverLetterJobRequirement(e.target.value)} 
                            label="Requirement of Target Job" 
                            type="text" 
                            multiline minRows={5} maxRows={15} />
                          </Grid>

                      
                           
                        </Grid>
                       
                
                <DialogActions sx={{p:0, pt:0}}>
                  <Button sx={{textTransform:"none"}} onClick={generateCoverLetter}>Generate Cover Letter</Button>
                  <Button sx={{textTransform:"none"}} onClick={handleCloseGenerateCoverLetterDialogue}>Cancel</Button>
                </DialogActions>
              </DialogContent>
              
            </Dialog>
            <Backdrop
              sx={{ flexDirection:"column",color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={generatingCoverLetter}
              onClick={handleClose}
            >
              <CircularProgress sx={{mb: 2}} color="inherit" />
              <Typography
              variant="subtitle1"
              color="white"
              component="div"
            >
              Generating Cover Letter......
            </Typography>

            </Backdrop>
            <PositionedSnackbar error={triggerAIErrorMessage}/>
            <Backdrop
              sx={{ flexDirection:"column",color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={generatingCV}
              onClick={handleClose}
            >
              <CircularProgress sx={{mb: 2}} color="inherit" />
              <Typography
              variant="subtitle1"
              color="white"
              component="div"
            >
              Generating Resume......
            </Typography>

            </Backdrop>

            <div>
              <a href={props.s3FilePath} download="resume.docx">
                <Button
                  // onClick={handleDownloadResume}
                  sx={{
                    textTransform: "none",
                    gap: 1,
                    justifyContent: "flex-start",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                >
                  <DownloadIcon />
                  Download DOCX
                </Button>
              </a>
            </div>
            

          
            <Button
              sx={{
                textTransform: "none",
                gap: 1,
                justifyContent: "flex-start",
                fontFamily: "Montserrat, sans-serif",
              }}
              onClick={() => navigate(`/cvBuilder/${props.resumeId}`)}
            >
              <EditIcon /> Edit
            </Button>
            <React.Fragment>
            <Button
              sx={{
                textTransform: "none",
                gap: 1,
                justifyContent: "flex-start",
                fontFamily: "Montserrat, sans-serif",
              }}
              onClick={handleClickOpen}
            >
              <DeleteForeverIcon /> Delete
            </Button>
            <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{fontWeight:'bold',fontFamily:'Exo'}} id="alert-dialog-title">
          {"Confirm Delete"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
           Are you sure you want to delete this resume?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{p:3, pt:0}}>
      
          <Button sx={{textTransform: "none", }} size='small' variant="contained" color="error" onClick={handleDelete} autoFocus>
             Delete
          </Button>
          <Button sx={{color:'black',textTransform: "none", }} size='small' onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
            {/* <Button sx={{ textTransform: "none", pl:0, justifyContent: "flex-start", fontFamily: "Montserrat, sans-serif",}}>
                more details
            </Button> */}
          </Box>
        </Box>
      </Card>
      </Grow>

    </>
  );
}





