import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate, useLocation, Link } from "react-router-dom";
import style from "./NavBar.module.css";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';

import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store";
import { logout } from "../slices/AuthSlice";
import { jwtDecode } from "jwt-decode";


import logo from "../img/logo9.png";

function NavigationBar() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();

  const isAuthenticated = useSelector<RootState>(
    (state) => state.auth.isAuthenticated
  );

  let user = "";
  let user_id: number | null = null;
  if (isAuthenticated) {
    let decoded: { user_id: number; username: string } = jwtDecode(
      localStorage.getItem("token")!
    );
    // console.log("decoded", decoded);
    user = decoded.username;
    user_id = decoded.user_id;
  }

  const logoutButton = () => {
    dispatch(logout());
    navigate("/");
    // window.location.href = "/";
  };

  return (
    <Navbar
      expand="lg"
      className={"bg-body" + " " + style.navBar}
      bg="light"
      data-bs-theme="light"
    >


      <Container className={style.navContainer}>
        <Navbar.Brand href="/jobSearch" className={style.navBarLogo}>
        <img className={style.navLogo} src={logo} alt="Logo" />
        
          
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className={style.navToggle}
        />
        <Navbar.Collapse id="basic-navbar-nav" className={style.navCollapse}>
          <Nav className={"me-auto" + " " + style.navItemContainer}>
            <div className={style.navItem}>
              <Nav.Link
                className={
                  style.navbarItem +
                  " " +
                  (location.pathname.includes("/jobSearch")
                    ? style.activeRoute
                    : "")
                }
                onClick={() => navigate("/jobSearch")}
              >
                Job Search
              </Nav.Link>


              

              
              {!isAuthenticated ? (
                ""
              ) : (
                <>
                <Nav.Link
                className={
                  style.navbarItem +
                  " " +
                  (location.pathname.includes("/cvBuilder")
                    ? style.activeRoute
                    : "")
                }
                onClick={() => navigate("/cvBuilder/create")}
              >
                Resume Builder
              </Nav.Link>

              <Nav.Link
                className={
                  style.navbarItem +
                  " " +
                  (location.pathname.includes("/coverLetterBuilder")
                    ? style.activeRoute
                    : "")
                }
                onClick={() => navigate("/coverLetterBuilder/create")}
              >
                Cover Letter Builder
              </Nav.Link>


                <Nav.Link
                  className={
                    style.navbarItem +
                    " " +
                    (location.pathname.includes("/dashboard")
                      ? style.activeRoute
                      : "")
                  }
                  onClick={() => navigate(`/dashboard`)}
                >
                  Dashboard
                </Nav.Link>
                <div className={style.buttonWhenCollapse}>
                      <Nav.Link
                    className={
                      style.navbarItem +
                      " " +
                      (location.pathname.includes("/profile")
                        ? style.activeRoute
                        : "")
                
                    }
                    onClick={() => navigate(`/profile`)}
                  >
                    Profile
                  </Nav.Link>
                  <Nav.Link
                  className={
                    style.navbarItem +
                    " " +
                    style.logoutButton
                  }
                  onClick={logoutButton}
                >
                  Logout
                </Nav.Link>
                    </div>
                    
                </>
              )}
            </div>
          </Nav>

          {!isAuthenticated ? (
            <div className={style.loginSignUp}>
              <Button
              
                className={style.loginButton}
                onClick={() => navigate("/login")}
                sx={{ textTransform: "none", fontWeight: 'Bold', fontSize: 15}}
                variant="outlined"
              >
                Login
              </Button>
              <Button
                className={style.loginButton}
                onClick={() => navigate("/register")}
                sx={{ textTransform: "none", marginLeft: 2, fontWeight: 'Bold', fontSize: 15}}
                variant="contained"
              >
                Register
              </Button>
            </div>
          ) : (
            <div>
              <React.Fragment>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {/* the popout of the icon when hovered over */}
                  <Tooltip title={user}>
                    <IconButton
                      onClick={handleClick}
                      size="small"
                      sx={{ ml: 2,  }}
                      aria-controls={open ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? "true" : undefined}
                      className={style.userButton}
                    >
                      <Avatar sx={{ width: 32, height: 32, }}>
                        {user[0]}
                      </Avatar>
                    </IconButton>
                  </Tooltip>
                </Box>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      borderRadius: 2,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      // "&::before": {
                      //   content: '""',
                      //   display: "block",
                      //   position: "absolute",
                      //   top: 0,
                      //   right: 14,
                      //   width: 10,
                      //   height: 10,
                      //   bgcolor: "background.paper",
                      //   transform: "translateY(-50%) rotate(45deg)",
                      //   zIndex: 0,
                      // },
                    },
                  }}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  <MenuItem
                    onClick={() => navigate(`/profile`)}
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemIcon>
                        <PersonIcon fontSize="small"/> 
                    </ListItemIcon>
                     Profile
                  </MenuItem>

                  {/* <MenuItem component={Link} to={`/dashboard/uid=${user_id}`} style={{ textDecoration: 'none' }}> */}
                  <MenuItem
                    onClick={() => navigate(`/dashboard`)}
                    style={{ textDecoration: "none" }}
                  >
                    <ListItemIcon>
                        <DashboardIcon fontSize="small"/> 
                    </ListItemIcon> Dashboard
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={logoutButton}>
                    <ListItemIcon>
                      <Logout fontSize="small" sx={{color:"#2962ff"}}/>
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </React.Fragment>
            </div>
          )}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
