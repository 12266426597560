import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface AuthState {
	isAuthenticated: boolean
    username: string | null 
    user_id: number | null
}

const initialState: AuthState = {
	isAuthenticated: localStorage.getItem('token') !== null,
    username: null,
    user_id: null,
}

export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		login: (state, action: PayloadAction<{user_id: number; username: string}>) => {
			state.username = action.payload.username
            state.user_id = action.payload.user_id
            state.isAuthenticated = true
		},
        logout: (state) => {
            state.username = null
            state.user_id = null
            localStorage.removeItem('token')
            state.isAuthenticated = false
        }
	}
})

export const { login, logout } = authSlice.actions
export default authSlice.reducer