import MuiTextField from "@mui/material/TextField";
import { ComponentProps, FormEventHandler, useState } from "react";

type ValidityStateKey = Extract<keyof ValidityState, string>;
type ValidityStateMessage = `${ValidityStateKey}Message`;

type Props = ComponentProps<typeof MuiTextField> & {
  [key in ValidityStateMessage]?: string;
};

export const TextField = ({ error, helperText, onInput, ...props }: Props) => {
  const [errorText, setErrorText] = useState<string | null>(null);
  const handleInvalid: FormEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    const target = event.target as HTMLInputElement;

    // set error text based on validity state
    for (const key in target.validity) {
      const stateKey = key as ValidityStateKey;
      const isError = target.validity[stateKey];
      if (!isError) {
        continue;
      }

      setErrorText(
        props[`${stateKey}Message` as const] ?? target.validationMessage
      );
    }
  };

  let theError = error;
  if (error === undefined) {
    theError = errorText !== null;
  }

  let theHelperText = helperText;
  if (helperText === undefined) {
    theHelperText = errorText;
  }

  return (
    <MuiTextField
      {...props}
      onInvalid={handleInvalid}
      error={theError}
      helperText={theHelperText}
      onInput={(event) => {
        // clear error text on input
        setErrorText(null);
        onInput?.(event);
      }}
    ></MuiTextField>
  );
};
