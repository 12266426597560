import { useQuery } from "@tanstack/react-query";

const source = process.env.REACT_APP_API_SERVER

export function useLoadUserProfile(user_id: number | null) {
    const { isLoading, error, data, isFetching } = useQuery({
      queryKey: ["userProfile"],
      queryFn: async () => {
        const res = await fetch(`${source}/profile/loadUserProfile/${user_id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
  
        const resp = await res.json();
        return resp;
      },
    });
  
    if (isLoading || isFetching) return "Data is coming";
  
    if (error) {
      return "Error occurred";
    }
  
    if (!data) {
      return [];
    }
  
    return data;
  }

//***********************************Update Personal Data******************************************* */

export async function updateProfile(updatedProfile: any) {
  console.log("updatedProfile api", updatedProfile);

  // console.log("resume_id", resume_id)
  const res = await fetch(`${source}/profile/updateProfile/${updatedProfile.userId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      userId: updatedProfile.userId,
      firstName: updatedProfile.firstName,
      lastName: updatedProfile.lastName,
      username: updatedProfile.username,
      email: updatedProfile.email,
      
      
    }),
  });

  const resp = await res.json();

  return resp;
}
  