import { DocxGenerator, PersonalInformationProps } from "./DocxGenerator";
import RenderDocx from "./CvPreviewer";
import style from "./CvBuilderCreate.module.css";
import { Fab } from "@mui/material";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { jwtDecode } from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';

import { useState, useEffect } from "react";

import {  createResume } from "../hooks/resumeAPI";

import { useParams } from "react-router-dom";
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";

export default function CvBuilderCreate() {

    const navigate = useNavigate();


    const isAuthenticated = useSelector<RootState>(
        (state) => state.auth.isAuthenticated
      );

    let user = "";
    let user_id: number | null = null;
    if (isAuthenticated) {
      let decoded: { user_id: number; username: string } = jwtDecode(
        localStorage.getItem("token")!
      );
      // console.log("decoded", decoded);
      user = decoded.username;
      user_id = decoded.user_id;
    }
 





  //****************************handle submission************************//

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        //***********************handle personal data*******************************//
        let personalDataSubmitted = {
        title: data.get("title"),
        firstName: data.get("firstName"),
        lastName: data.get("lastName"),
        phone: data.get("phone"),
        email: data.get("email"),
        targetJob: data.get("targetPosition"),
        userId: user_id,
        };
        console.log("personalDataSubmitted", personalDataSubmitted);

        //**********create/update personal data useMutation**********//
        let createdResumeId:any = await OnCreateResume.mutateAsync({ personalDataSubmitted });

        
        console.log("createdResumeId", createdResumeId);
        
        let resumeId:Number = createdResumeId.createPersonalData[0].resume_id;    

        console.log("resumeId", resumeId);

        //***********navigate to the next page******************//
        navigate(`/cvBuilder/${resumeId}`);


        //************************************************************************//


    
        
    
    };

  //*****************************handle personal data****************//

  
  const queryClient = useQueryClient();
  const OnCreateResume = useMutation({
    mutationFn: async (data: { personalDataSubmitted:any }) =>
        createResume(data.personalDataSubmitted),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ["resumeData"],
        exact: true,
      }),
  });







  //

  return (
    <>
        {isAuthenticated ? (
            <div className={style.cvBuilderBigContainer}>
        
        
        
        
                    <div>
                
                    <div className={style.cvBuilderContainer}>
                    <div className={style.cvFormArea}>
                    
                        
                        <Container component="main" maxWidth="md">
                        <CssBaseline />
                        <Box
                            sx={{
                            marginTop: 2,
                            display: "flex",
                            flexDirection: "column",
                            }}
                        >
                            <Box
                            component="form"
                            noValidate
                            onSubmit={handleSubmit}
                            sx={{ mt: 3 }}
                            >
                                    <Typography
                                component="h1"
                                // variant="h3"
                                align="left"
                                sx={{ 
                                    mb: 1,
                                    fontWeight: 'bold',
                                    // fontSize: '20px',
                                    // textAlign: "center",
                                    width: "100%",
                                }}
                                className={style.cvBuilderCreateTitle}
                            >
                                Kickstart Your Resume!
                            </Typography>
                            <Grid
                                container
                                sx={{ mb: 2, textAlign: "center" }}
                            >


                            
                                <Typography
                                component="h1"
                                variant="subtitle1"
                                align="left"
                                sx={{ 
                                    mb: 1,
                                    fontWeight: 'bold',
                                    // textAlign: "center",
                                    // width: "100%",
                                }}
                                className={style.cvBuilderCreateHelpText}
                            >
                                Let's start with some basic information
                            </Typography>

                            
                                <Grid item xs={12}
                                sx={{ 
                                    borderBottom: 2,
                                    borderColor: "#2196f3",
                                    
                                }}>
                                
                                {/* <TextField
                                    inputProps={{min: 0, style: { textAlign: 'center' }}}
                                    sx={{
                                        mb: 3,
                                    }}
                                    name="title"
                                    id="newTitle"
                                    label="Title"
                                /> */}
                                </Grid>
                            </Grid>

                            {/* <Typography
                                component="h1"
                                variant="h6"
                                align="left"
                                sx={{ 
                                    mb: 2,
                                    fontWeight: 'bold',
                                }}
                                className={style.cvBuilderSectionTitle}
                            >
                                Basic Information
                            </Typography> */}
                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    variant="filled"
                                    name="firstName"
                                    fullWidth
                                    id="newFirstName"
                                    label="First Name"
                                    autoFocus
                                />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    variant="filled"
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    variant="filled"
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    variant="filled"
                                    fullWidth
                                    name="phone"
                                    label="Phone"
                                    type="phone"
                                    id="phone"
                                />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                <TextField
                                    size="small"
                                    variant="filled"
                                    fullWidth
                                    name="targetPosition"
                                    label="Target Job/Position"
                                    id="targetPosition"
                                />
                                </Grid>
                            </Grid>
                        
                    


                            
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ 
                                    mt: 3,
                                    mb: 2,
                                    textTransform: "none",
                                    fontFamily: "Exo, sans-serif",
                                }}
                                endIcon={<SendIcon />}
                            
                            >
                                Start Building Resume
                            </Button>
                        
                            </Box>

                        </Box>
                        </Container>
                        </div>
                    
                    </div>
                
                </div>
                
            
        
        
        

        </div>
        
        ) : (
          
               <Typography
               component="h1"
               variant="h6"
            //    align="left"
               sx={{ 
                   mt: 4,
                   fontWeight: 'bold',
                   // textAlign: "center",
                   // width: "100%",
               }}
             
           >
              Please <Link href="/login" underline="hover">login </Link>to access this page
           </Typography>
            
        )}
    </>
  );
}
