export function OnHandleArrayDescriptionChange (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, 
    index:number, 
    state:Array<string>, 
    setState:Function ) {
      const newValue = [...state]
      newValue[index] = event.currentTarget.value
      setState(newValue)
  }


export type NewEmploymentDescription = {
    tmpEmp_id: number
    value:string
  }

export function changeEmploymentArrayDescription(newHistory: NewEmploymentDescription[], value: string, id:number): NewEmploymentDescription[] {
    return newHistory.map((item: NewEmploymentDescription) => {
      if (item.tmpEmp_id === id) {
        return { ...item, value: value };
      }
      return item;
    });
  }

  
export type NewEducationDescription = {
    tmpEdu_id: number
    value:string
  }

export function changeEducationArrayDescription(newHistory: NewEducationDescription[], value: string, id:number): NewEducationDescription[] {
    return newHistory.map((item: NewEducationDescription) => {
      if (item.tmpEdu_id === id) {
        return { ...item, value: value };
      }
      return item;
    });
  }


export type NewSkillDescription = {
  tmpSkill_id: number
  value:string
}

export function changeSkillArrayDescription(newHistory:NewSkillDescription[], value: string, id:number): NewSkillDescription[] {
    return newHistory.map((item: any) => {
      if (item.tmpSkill_id === id) {
        return { ...item, value: value };
      }
      return item;
    });
  }
