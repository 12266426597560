import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { useMemo } from "react";

export default function CvPreviewer(props: any) {

  const { url ,rerender} = props;
  const docx = [{ uri: url , fileType:"docx"}];

  const DocxUseMemo = useMemo(() => (
    <DocViewer
      prefetchMethod="GET"
      pluginRenderers={DocViewerRenderers}
      documents={docx}
      config={{
        header: {
          disableHeader: true,
        },
        // pdfZoom: {
        //   defaultZoom: 10,
        //   zoomJump: 0.8,
        // }
      }}
    />
  ), [url,rerender]);

  return (
    <>
      {!docx
        ? ""
        : 
        <>
        {DocxUseMemo}
        </>
          // <DocViewer
          //   prefetchMethod="GET"
          //   pluginRenderers={DocViewerRenderers}
          //   documents={docx}
          //   config={{
          //     header: {
          //       disableHeader:true,
          //     },
          //     // pdfZoom: {
          //     //   defaultZoom: 10,
          //     //   zoomJump:0.8,
          //     // }
          //   }}
          // />
      }
    </>
  );
}
