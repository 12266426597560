import { Button, Card, LinearProgress, Typography } from '@mui/material'
import PublishSharpIcon from '@mui/icons-material/PublishSharp';
import TipsAndUpdatesSharpIcon from '@mui/icons-material/TipsAndUpdatesSharp';
import ReplayIcon from '@mui/icons-material/Replay';
import { useState } from 'react';

export type AIsuggestionProps = {
  content:string
  handleChange:(aiResult:string,index:number)=> void
  aiApi:Function,
  index?:number,
  skillName?:string,
  jobTitle?:string,
  jobRequirement?:string
}

export function AIsuggestion(props:AIsuggestionProps) {
  const [ isLoading, setIsLoading ] = useState<boolean>(false)
  const [ aiSuggestion, setAiSuggestion ] = useState<string>("")

  const getDescriptionSuggestion = async () => {
    setIsLoading(true)
    let suggestion = await props.aiApi(
      props.content, 
      props.skillName, 
      props.jobTitle, 
      props.jobRequirement,
      )
    if (suggestion) {setIsLoading(false)}
    setAiSuggestion(suggestion.suggestion)
  }

  const applySuggestion = () => {
    props.handleChange(aiSuggestion,props.index!)
  }

    return (
        <>
            {
              isLoading
              ? <>
              <Typography sx={{mt:1}}>"Analyzing by AI...."</Typography>
              <LinearProgress />
              </>
              : (
                aiSuggestion && props.content
                ? <>
                    <Card sx={{p:2}} variant="outlined">
                      <Typography sx={{mt:1, mb:1}}>
                        <TipsAndUpdatesSharpIcon sx={{color:'#ffc107'}}/> <u>{"Here is the suggestion from AI:"}</u>
                        <br></br>
                        <Typography sx={{mt:1, whiteSpace:"pre-line"}}>
                          {aiSuggestion}
                        </Typography>
                      </Typography>
                      <Button size="small" sx={{mt:1, mb:1, textTransform: "none"}} variant="contained" startIcon={<ReplayIcon />} color="info" onClick={getDescriptionSuggestion}>
                        Make Another Suggestion
                      </Button>
                      <Button size='small' sx={{ml:3, textTransform: "none"}} variant="contained" startIcon={<PublishSharpIcon />} color="info" onClick={applySuggestion}>
                        Apply AI Suggestion
                      </Button>
                    </Card>
                  </>
                : <Button size='small' sx={{mt:1, mb:1, textTransform: "none"}} variant="contained" startIcon={<TipsAndUpdatesSharpIcon />} color="info" onClick={getDescriptionSuggestion}>
                    Get AI Suggestion
                  </Button>
              )
            }
        </>
    )
}