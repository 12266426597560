import { useQuery } from "@tanstack/react-query";

const source = process.env.REACT_APP_API_SERVER

export function useLoadCoverLetterData(coverLetter_id: number | null) {
  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["coverLetterData"],
    queryFn: async () => {
      console.log("coverLetter_id", coverLetter_id);
      if (coverLetter_id) {
        const res = await fetch(
          `${source}/coverLetterBuilder/loadCoverLetterData/${coverLetter_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const resp = await res.json();
        return resp;
      } else {
        return [];
      }
    },
    // retryOnMount: false,
    // retry: false,
    // refetchOnWindowFocus: false,
  });
  // console.log({
  //   isFetching,
  //   isLoading,
  //   error,
  //   data,
  // });

  if (isFetching) {
    return "Data is coming";
  }

  if (error) {
    return "Error occurred";
  }

  if (!data || data.length === 0) {
    return null;
  }

  // console.log("data: ",data)
  return data;
}

//***********************************Create Letter******************************************* */

export async function createCoverLetter(coverLetterDataSubmitted: any) {
  console.log("createResume in API", coverLetterDataSubmitted);
  const res = await fetch(`${source}/coverLetterBuilder/createCoverLetter`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
        title: coverLetterDataSubmitted.title,
        firstName: coverLetterDataSubmitted.firstName,
        lastName: coverLetterDataSubmitted.lastName,
        position: coverLetterDataSubmitted.position,
        email: coverLetterDataSubmitted.email,
        phone: coverLetterDataSubmitted.phone,
        // address: coverLetterDataSubmitted.address,
        employerName: coverLetterDataSubmitted.employerName,
        employerCompany: coverLetterDataSubmitted.employerCompany,
        userId: coverLetterDataSubmitted.userId,
    }),
  });

  const resp = await res.json();

  console.log("resp", resp);

  return resp;
}

//***********************************Update Cover Letter******************************************* */

export async function updateCoverLetter(coverLetterDataSubmitted: any) {
  console.log("updateCoverLetter in API", coverLetterDataSubmitted);
  const res = await fetch(`${source}/coverLetterBuilder/updateCoverLetter`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
        title: coverLetterDataSubmitted.title,
        firstName: coverLetterDataSubmitted.firstName,
        lastName: coverLetterDataSubmitted.lastName,
        position: coverLetterDataSubmitted.position,
        email: coverLetterDataSubmitted.email,
        phone: coverLetterDataSubmitted.phone,
        // address: coverLetterDataSubmitted.address,
        article: coverLetterDataSubmitted.article,
        employerName: coverLetterDataSubmitted.employerName,
        employerCompany: coverLetterDataSubmitted.employerCompany,
        userId: coverLetterDataSubmitted.userId,
        coverLetterId: coverLetterDataSubmitted.coverLetterId,
        jobRequirement: coverLetterDataSubmitted.jobRequirement,
    }),
  });

  const resp = await res.json();

  console.log("resp", resp);

  return resp;
}