const source = process.env.REACT_APP_API_SERVER

export async function aiGenerateCoverLetterFromCv (userId:number, resumeId:number, coverLetterJobTitle:string, coverLetterJobCompany:string, coverLetterJobRequirement:string, coverLetterEmployerName:string) {

  console.log('Data passing to controller: ', userId, resumeId, coverLetterJobTitle, coverLetterJobCompany, coverLetterJobRequirement, coverLetterEmployerName)
  const res = await fetch(`${source}/generateCoverLetterFromCv`, {
    method:"POST",
    headers: {
      "Content-Type":"application/json"
    },
    body:JSON.stringify({
      "userId":userId,
      "resumeId":resumeId, 
      "coverLetterJobTitle":coverLetterJobTitle, 
      "coverLetterJobCompany":coverLetterJobCompany, 
      "coverLetterJobRequirement":coverLetterJobRequirement,
      "coverLetterEmployerName":coverLetterEmployerName,

    })
  })
  const resp = await res.json()
  console.log("aiGenerateCoverLetterFromCv resp in API: ", resp)
  return resp
}

export async function tailorMadeCVFromAI (resumeId:number, targetJobTitle:string, company:string, targetJobRequirement:string, user_id:number) {
  console.log(`Data passing to controller:\n1. Title: ${targetJobTitle}\n2. Company: ${company}\n3. Requirement: ${targetJobRequirement}`)
  const res = await fetch(`${source}/tailormadeCV`, {
    method:"POST",
    headers:{
      "Content-Type":"application/json"
    },
    body: JSON.stringify({
      "resumeId":resumeId,
      "targetJobTitle":targetJobTitle,
      "company":company,
      "targetJobRequirement":targetJobRequirement,
      "userId":user_id,
    })
  })
  const resp = await res.json()
  return resp
}

export async function skillDescriptionSuggestion (skillDescription:string, skillName: string) {
  const res = await fetch(`${source}/skillDescriptionSuggestion`, {
    method:"POST",
    headers: {
      "Content-Type":"application/json"
    },
    body: JSON.stringify({
      "skillName":skillName,
      "skillDescription":skillDescription,
    })
  })
  const resp = res.json()
  return resp
}

export async function employmentDescriptionSuggestion (description:string) {
    let apiPath = "employmentDescriptionSuggestion"
    let suggestion = await aiSuggestion(description,apiPath)
    return suggestion
}

export async function extraCurricularActivityDescriptionSuggestion (description:string) {
    let apiPath = "extraCurricularActivityDescriptionSuggestion"
    let suggestion = await aiSuggestion(description,apiPath)
    return suggestion
}

export async function educationDescriptionSuggestion (description:string) {
    let apiPath = "educationDescriptionSuggestion"
    let suggestion = await aiSuggestion(description,apiPath)
    return suggestion
}

export async function summaryDescriptionSuggestion (description:string) {
    let apiPath = "summaryDescriptionSuggestion"
    let suggestion = await aiSuggestion(description,apiPath)
    return suggestion
}

export async function tidyUpJobAdDescription (description:string) {
  let apiPath = "tidyUpJobAdDescription"
  let suggestion = await aiSuggestion(description,apiPath)
  return suggestion
}


async function aiSuggestion (description:string, apiPath:string) {
  // console.log("content waiting for ai anaylsis: ", description)
  const res = await fetch(`${source}/${apiPath}`, {
    method:"POST",
    headers: {
      "Content-Type":"application/json"
    },
    body: JSON.stringify({"description":description})
  })
  const resp = await res.json()
  return resp
}


export async function articleDescriptionSuggestion ( article: string, skillName:string, jobTitle:string, jobRequirement: string, ) {
  // console.log("Data passing to controller: ", jobTitle, jobRequirement, article)
  const res = await fetch(`${source}/coverLetterArticleSuggestion`, {
    method:"POST",
    headers: {
      "Content-Type":"application/json"
    },
    body: JSON.stringify({
      "jobTitle":jobTitle,
      "jobRequirement":jobRequirement,
      "article":article,  
    })
  })
  const resp = res.json()
  return resp
}