import { useQuery } from "@tanstack/react-query";

const source = process.env.REACT_APP_API_SERVER

export function useLoadResumeData(resume_id: number | null) {
  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["resumeData"],
    queryFn: async () => {
      console.log("resume_id", resume_id);
      if (resume_id) {
        const res = await fetch(
          `${source}/cvBuilder/loadResumeData/${resume_id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        const resp = await res.json();
        return resp;
      } else {
        return [];
      }
    },
    // retryOnMount: false,
    // retry: false,
    // refetchOnWindowFocus: false,
  });
  // console.log({
  //   isFetching,
  //   isLoading,
  //   error,
  //   data,
  // });

  if (isFetching) {
    return "Data is coming";
  }

  if (error) {
    return "Error occurred";
  }

  if (!data || data.length === 0) {
    return null;
  }

  // console.log("data: ",data)
  return data;
}

//***********************************Create Resume******************************************* */

export async function createResume(personalDataSubmitted: any) {
  console.log("createResume in API", personalDataSubmitted);
  const res = await fetch(`${source}/cvBuilder/createResume`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      title: personalDataSubmitted.title,
      firstName: personalDataSubmitted.firstName,
      lastName: personalDataSubmitted.lastName,
      email: personalDataSubmitted.email,
      phone: personalDataSubmitted.phone,
      targetJob: personalDataSubmitted.targetJob,
      userId: personalDataSubmitted.userId,
    }),
  });

  const resp = await res.json();

  console.log("resp", resp);

  return resp;
}

//***********************************Update Personal Data******************************************* */

export async function updatePersonalData(personalDataSubmitted: any) {
  console.log("updatePersonalData", personalDataSubmitted);

  // console.log("resume_id", resume_id)
  const res = await fetch(`${source}/cvBuilder/updatePersonalData`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      resume_id: personalDataSubmitted.resume_id,
      title: personalDataSubmitted.title,
      firstName: personalDataSubmitted.firstName,
      lastName: personalDataSubmitted.lastName,
      email: personalDataSubmitted.email,
      phone: personalDataSubmitted.phone,
      summary: personalDataSubmitted.summary,
      targetJob: personalDataSubmitted.targetJob,
      template: personalDataSubmitted.template,
    }),
  });

  const resp = await res.json();

  return resp;
}

//***********************************Working Experience******************************************* */

export async function updateWorkingExperience(
  existingWorkingExperienceSubmitted: any
) {
  console.log(
    "updateWorkingExperience in API",
    existingWorkingExperienceSubmitted
  );
  const res = await fetch(`${source}/cvBuilder/updateWorkingExperience`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      experienceId: existingWorkingExperienceSubmitted.experienceId,
      jobTitle: existingWorkingExperienceSubmitted.jobTitle,
      company: existingWorkingExperienceSubmitted.company,
      startDate: existingWorkingExperienceSubmitted.startDate,
      endDate: existingWorkingExperienceSubmitted.endDate,
      description: existingWorkingExperienceSubmitted.jobDescription,
    }),
  });

  const resp = await res.json();

  return resp;
}

// export async function createWorkingExperience(
//   newWorkingExperienceSubmitted: any
// ) {
//   console.log("createWorkingExperience in API", newWorkingExperienceSubmitted);
//   const res = await fetch(`${source}/cvBuilder/createWorkingExperience`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({
//       resumeId: newWorkingExperienceSubmitted.resumeId,
//       jobTitle: newWorkingExperienceSubmitted.jobTitle,
//       company: newWorkingExperienceSubmitted.company,
//       startDate: newWorkingExperienceSubmitted.startDate,
//       endDate: newWorkingExperienceSubmitted.endDate,
//       jobDescription: newWorkingExperienceSubmitted.jobDescription,
//     }),
//   });

//   const resp = await res.json();

//   return resp;
// }

export async function createWorkingExperience(
  newWorkingExperienceSubmitted: any
) {
  console.log("createWorkingExperience in API", newWorkingExperienceSubmitted);
  const res = await fetch(`${source}/cvBuilder/createWorkingExperience`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newWorkingExperienceSubmitted),
  });

  const resp = await res.json();

  return resp;
}


export async function deleteWorkingExperience(deletedExperienceId: any) {
  console.log("deletedExperienceId in API", deletedExperienceId);
  const res = await fetch(`${source}/cvBuilder/deleteWorkingExperience`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      deletedExperienceId,
    }),
  });

  const resp = await res.json();

  return resp;
}

//*********************************************Education******************************************* */

export async function updateEducation(existingEducationSubmitted: any) {
  console.log("updateEducation in API", existingEducationSubmitted);
  const res = await fetch(`${source}/cvBuilder/updateEducation`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      educationId: existingEducationSubmitted.educationId,
      resumeId: existingEducationSubmitted.resumeId,
      educationTitle: existingEducationSubmitted.educationTitle,
      school: existingEducationSubmitted.school,
      eduStartDate: existingEducationSubmitted.eduStartDate,
      eduEndDate: existingEducationSubmitted.eduEndDate,
      eduDescription: existingEducationSubmitted.eduDescription,
    }),
  });

  const resp = await res.json();

  return resp;
}

// export async function createEducation(newEducationSubmitted: any) {
//   console.log("createEducation in API", newEducationSubmitted);
//   const res = await fetch(`${source}/cvBuilder/createEducation`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({
//       // educationId: newEducationSubmitted.educationId,
//       resumeId: newEducationSubmitted.resumeId,
//       educationTitle: newEducationSubmitted.educationTitle,
//       school: newEducationSubmitted.school,
//       eduStartDate: newEducationSubmitted.eduStartDate,
//       eduEndDate: newEducationSubmitted.eduEndDate,
//       eduDescription: newEducationSubmitted.eduDescription,
//     }),
//   });

//   const resp = await res.json();

//   return resp;
// }


export async function createEducation(newEducationSubmitted: any) {
  console.log("createEducation in API", newEducationSubmitted);
  const res = await fetch(`${source}/cvBuilder/createEducation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newEducationSubmitted),
  });

  const resp = await res.json();

  return resp;
}



export async function deleteEducation(deletedEducationId: any) {
  console.log("deletedEducationId in API", deletedEducationId);
  const res = await fetch(`${source}/cvBuilder/deleteEducation`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      deletedEducationId,
    }),
  });

  const resp = await res.json();

  return resp;
}

//*********************************************Reference******************************************* */

export async function updateReference(existingReferenceSubmitted: any) {
  console.log("updateReference in API", existingReferenceSubmitted);
  const res = await fetch(`${source}/cvBuilder/updateReference`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      referenceId: existingReferenceSubmitted.referenceId,
      resumeId: existingReferenceSubmitted.resumeId,
      refereeName: existingReferenceSubmitted.refereeName,
      refereePosition: existingReferenceSubmitted.refereePosition,
      organization: existingReferenceSubmitted.organization,
      relationship: existingReferenceSubmitted.relationship,
      refereePhone: existingReferenceSubmitted.refereePhone,
      refereeEmail: existingReferenceSubmitted.refereeEmail,
    }),
  });

  const resp = await res.json();

  return resp;
}

// export async function createReference(newReferenceSubmitted: any) {
//   console.log("createReference in API", newReferenceSubmitted);
//   const res = await fetch(`${source}/cvBuilder/createReference`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({
//       // referenceId: newReferenceSubmitted.referenceId,
//       resumeId: newReferenceSubmitted.resumeId,
//       refereeName: newReferenceSubmitted.refereeName,
//       refereePosition: newReferenceSubmitted.refereePosition,
//       organization: newReferenceSubmitted.organization,
//       relationship: newReferenceSubmitted.relationship,
//       refereePhone: newReferenceSubmitted.refereePhone,
//       refereeEmail: newReferenceSubmitted.refereeEmail,
//     }),
//   });

//   const resp = await res.json();

//   return resp;
// }
export async function createReference(newReferenceSubmitted: any) {
  console.log("createReference in API", newReferenceSubmitted);
  const res = await fetch(`${source}/cvBuilder/createReference`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newReferenceSubmitted),
  });

  const resp = await res.json();

  return resp;
}

export async function deleteReference(deletedReferenceId: any) {
  console.log("deletedReferenceId in API", deletedReferenceId);
  const res = await fetch(`${source}/cvBuilder/deleteReference`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      deletedReferenceId,
    }),
  });

  const resp = await res.json();

  return resp;
}

//*********************************************Language********************************************//

export async function updateLanguage(existingLanguageSubmitted: any) {
  console.log("updateLanguage in API", existingLanguageSubmitted);
  const res = await fetch(`${source}/cvBuilder/updateLanguage`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      languageId: existingLanguageSubmitted.languageId,
      resumeId: existingLanguageSubmitted.resumeId,
      language: existingLanguageSubmitted.language,
      level: existingLanguageSubmitted.level,
      
  }),
})

  const resp = await res.json();

  return resp;
}

// export async function createLanguage(newLanguageSubmitted: any) {
//   console.log("createLanguage in API", newLanguageSubmitted);
//   const res = await fetch(`${source}/cvBuilder/createLanguage`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({
      
//       resumeId: newLanguageSubmitted.resumeId,
//       // languageId: newLanguageSubmitted.languageId,
//       language: newLanguageSubmitted.language,
//       level: newLanguageSubmitted.level,
  
//     }),
//   });

//   const resp = await res.json();

//   return resp;
// }

export async function createLanguage(newLanguageSubmitted: any) {
  console.log("createLanguage in API", newLanguageSubmitted);
  const res = await fetch(`${source}/cvBuilder/createLanguage`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newLanguageSubmitted),
  });

  const resp = await res.json();

  return resp;
}

export async function deleteLanguage(deletedLanguageId: any) {
  console.log("deletedLanguageId in API", deletedLanguageId);
  const res = await fetch(`${source}/cvBuilder/deleteLanguage`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      deletedLanguageId,
    }),
  });

  const resp = await res.json();

  return resp;
}


//*********************************************Skill********************************************//

export async function updateSkill(existingSkillSubmitted: any) {
  console.log("updateSkill in API", existingSkillSubmitted);
  const res = await fetch(`${source}/cvBuilder/updateSkill`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      skillId: existingSkillSubmitted.skillId,
      resumeId: existingSkillSubmitted.resumeId,
      skill: existingSkillSubmitted.skill,
      skillDescription: existingSkillSubmitted.skillDescription,
      
  }),
})

  const resp = await res.json();

  return resp;
}

// export async function createSkill(newSkillSubmitted: any) {
//   console.log("createSkill in API", newSkillSubmitted);
//   const res = await fetch(`${source}/cvBuilder/createSkill`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify({
    
//       resumeId: newSkillSubmitted.resumeId,
//       // skillId: newSkillSubmitted.skillId,
//       skill: newSkillSubmitted.skill,
//       skillDescription: newSkillSubmitted.skillDescription,
  
//     }),
//   });

//   const resp = await res.json();

//   return resp;
// }

export async function createSkill(newSkillSubmitted: any) {
  console.log("createSkill in API", newSkillSubmitted);
  const res = await fetch(`${source}/cvBuilder/createSkill`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newSkillSubmitted),
  });

  const resp = await res.json();

  return resp;
}


export async function deleteSkill(deletedSkillId: any) {
  console.log("deletedSkillId in API", deletedSkillId);
  const res = await fetch(`${source}/cvBuilder/deleteSkill`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      deletedSkillId,
    }),
  });

  const resp = await res.json();

  return resp;
}