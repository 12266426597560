import React from "react";
// import logo from './logo.svg';
// import './App.css';
import style from "./App.module.css";
import NavBar from "./cp-navbar/NavBar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navigate, Routes, Route } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import CvBuilder from "./cp-resume/CvBuilder";
import CvBuilderCreate from "./cp-resume/CvBuilderCreate";

import JobSearch from "./cp-job/JobSearch";
import JobCategory from "./cp-job/JobCategory";
import JobSuggestion from "./cp-job/JobSuggestion";
import JobByCategory from "./cp-job/JobByCategory";

import Dashboard from "./cp-dashboard/Dashboard";
import UserProfile from "./cp-user/UserProfile";
import LoginPage from "./cp-user/LoginPage";
import RegisterPage from "./cp-user/RegisterPage";

import CoverLetterBuilder from "./cp-coverLetter/CoverLetterBuilder";
import CoverLetterBuilderCreate from "./cp-coverLetter/CoverLetterBuilderCreate";
import { JobSearchPage } from "./cp-job/JobSearchPage";
import JobResultByCategory from "./cp-job/JobResultByCategory";


function App() {

  const location = useLocation();
  return (
    <div className="App">

      {location.pathname.includes( "/cvBuilder") && location.pathname !== "/cvBuilder/create" 
        
        || location.pathname.includes( "/coverLetterBuilder")&& location.pathname !== "/coverLetterBuilder/create"
      ? 
      <main className={style.contentContainer}>
        <Routes>
          <Route path="/cvBuilder/:cvId" element={<CvBuilder />} />
          <Route path="/coverLetterBuilder/:cLetterId" element={<CoverLetterBuilder/>} />
        </Routes>
      </main>
      :
      <>
        <NavBar />
        {/* <NarBarNew/> */}
        <main className={style.contentContainer}>
          <Routes>
            <Route path="/" element={<Navigate to="/jobSearch" />} />

            <Route path="/jobSearch" element={<JobCategory />} />
            <Route path="/jobSearch/:category" element={<JobResultByCategory />} />
            <Route path="jobSuggestion" element={<JobSuggestion />} />

            <Route path="/jobSearch/search" element={<JobSearchPage />}/>
          
              
           

            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />

            <Route path="/cvBuilder/:cvId" element={<CvBuilder />} />
            <Route path="/cvBuilder/create" element={<CvBuilderCreate />} />

            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/profile" element={<UserProfile />} />

            <Route path="/coverLetterBuilder/create" element={<CoverLetterBuilderCreate/>} />
          </Routes>
        </main>

      </>
      }

    
    </div>
  );
}

export default App;
