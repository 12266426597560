import { useNavigate } from "react-router-dom";
import style from "./JobSearch.module.css";
import { Autocomplete, Button, TextField, debounce } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";

type Option = {
  id: number;
  label: string;
};

type Job = {
  id: number;
  category: string;
  title: string;
  company: string;
  requirement: string;
};

const source = process.env.REACT_APP_API_SERVER;

type Props = {
  defaultSearch?: string;
  onSearch?: (query: string) => void;
};

export default function JobSearch({ defaultSearch, onSearch }: Props) {
  const navigate = useNavigate();
  // search bar
  const [inputValue, setInputValue] = useState(defaultSearch ?? "");
  const [options, setOptions] = useState<Option[]>([]);

  const fetchJobs = useCallback(
    debounce(async (query: string) => {
      const response = await fetch(`${source}/jobList?search=${query}`);
      if (!response.ok) {
        throw new Error("Network response is fail");
      }
      const jobs: Job[] = await response.json();
      setOptions(jobs.map(({ id, title }) => ({ id, label: title })));
    }, 200),
    []
  );

  useEffect(() => {
    if (inputValue.length < 2) {
      setOptions([]);
      return;
    }
    fetchJobs(inputValue);

    return () => {
      fetchJobs.clear();
    };
  }, [inputValue]);

  const handleSearch = (inputValue: string) => {
    if (!inputValue) return
    if (onSearch) {
      onSearch(inputValue);
    } else {
      navigate(`/jobSearch/search?search=${inputValue}`);
    }
  };

  return (
    <div className={style.pageContainer}>
      <div className={style.jobSearchBar}>
        <div className={style.jobSearchContainer}>
          <h1 className={style.searchTitle}>Find your dream Job</h1>
          <form
            className={style.jobSearchForm}
            onSubmit={(event) => {
              console.debug("onSubmit", inputValue);
              event.preventDefault();
              handleSearch(inputValue);
              window.location.reload()
            }}
          >
            <Autocomplete<Option, undefined, undefined, boolean>
              freeSolo
              disablePortal
              blurOnSelect
              id="combo-box-demo"
              options={options}
              sx={{ maxWidth: 500, width: "100%" }}
              filterOptions={(options) => options}
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  handleSearch(newValue);
                } else if (newValue?.label) {
                  handleSearch(newValue?.label);
                }
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              renderOption={(props, option, { inputValue }) => {
                const matches = match(option.label, inputValue, {
                  insideWords: true,
                });
                const parts = parse(option.label, matches);

                return (
                  <li {...props} key={option.id}>
                    <div>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{
                            fontWeight: part.highlight ? 700 : 400,
                          }}
                        >
                          {part.text}
                        </span>
                      ))}
                    </div>
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ backgroundColor: "white", borderRadius:4}}
                  placeholder="Enter Title"
                  // label="Enter Title"

                />
              )}
            />
            <Button variant="contained" type="submit" sx={{height: 50, textTransform:"none" }}>
              Search
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
}
