import style from "./JobSearchPage.module.css";
import { Button, ButtonBase, Link, Pagination } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import JobSearch from "./JobSearch";
import { useSearchParams } from "react-router-dom";
import cx from "classnames";
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';

type Job = {
  id: number;
  category: string;
  title: string;
  company: string;
  requirement: string;
  link:string;
};

const source = process.env.REACT_APP_API_SERVER

export const JobSearchPage = () => {
  const [searchParams, setSearchParams] = useSearchParams({ search: "" });
  const initialSearch = searchParams.get("search") ?? "";

  // search result
  const [jobs, setJobs] = useState<Job[] | null>(null);
  const [selectedJob, setJob] = useState<Job | null>(null);

  const searchJobs = async (search: string) => {
    if (search.length === 0) {
      return;
    }

    const response = await fetch(`${source}/jobList?search=${search}`);
    if (!response.ok) {
      throw new Error("Network response is fail");
    }
    const jobs: Job[] = await response.json();
    setJobs(jobs);
    
    return jobs;
  };

  useEffect(() => {
    (async () => {
      const jobs = await searchJobs(initialSearch);
      const jobId = searchParams.get("id");

      if (jobId && jobs) {
        const job = jobs.find(({ id }) => id + "" === jobId);
        if (job) {
          setJob(job);
        } else {
          setSearchParams({ search: initialSearch });
        }
      }
    })();
  }, []);

  // Pagination
  const [page, setPage] = useState(1);
  const [ totalPages, setTotalPage ] = useState(0)
  const [ pageJobs, setPageJobs ] = useState<Job[]>([])
  const handlePageChange = (event:any, value:any) => {setPage(value)}
  useEffect(()=>{
    const itemsPerPage = 10
    const totalItems = jobs?.length
    setTotalPage(Math.ceil(totalItems! / itemsPerPage))
    
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    if (jobs){
      setPageJobs(jobs.slice(startIndex, endIndex))
    }
    window.scrollTo({top: 0});
 },[jobs,page])


  return (
    <div className={style.page}>
      <JobSearch
        defaultSearch={initialSearch}
        onSearch={(query) => {
          searchJobs(query);
          setSearchParams({ search: query });
        }}
      />
      <div className={style.resultBody}>
        
        <div className={style.resultLayout}>
          
            <ul className={style.jobSearchList}>
              <div className={style.resultBar}>Search Result: <b>{jobs?.length} {jobs?.length === 1 ? "Job" :"Jobs"}</b></div>
              {jobs?(  
              <>
              {pageJobs.map((job) => {
                const { title, company, id, category } = job;
                return (
                  <ButtonBase
                      className={style.cardClickable}
                      onClick={() => {
                        setJob(job);
                        const search = searchParams.get("search");
                        setSearchParams({
                          id: job.id + "",
                          ...(search ? { search } : {}),
                        });
                      }}
                    >
                  <Card
                    key={`${company}-${title}`}
                    component="li"
                    className={cx(style.jobCard, {
                      [style.jobCardSelected]: id === selectedJob?.id,
                    })}
                    sx={{ maxWidth: 400, boxShadow: 0}}
                  >
                    
                      <CardContent  sx={{p:0, pb: 0}}>
                        <Typography 
                          component="h1"
                          variant="h6"
                          align="left"
                          sx={{ fontFamily:'Exo', fontWeight: 'bold'}}
                        >
                          {title}
                        </Typography>
                        <Typography
                           component="h1"
                           variant="subtitle2"
                           align="left"
                           sx={{ fontFamily:'Exo',}}
                          color="text.secondary"
                          gutterBottom
                        >
                          {company}
                        </Typography>
                        <Chip label={category.split('_').join(' ')}/>
                      </CardContent>
                  
                    </Card>
                  </ButtonBase>
                );
              })}

              </>
              
              
            ) : (
              <Skeleton variant="rectangular" width={400} height={170} sx={{borderRadius:"10px"}} />
            )
            
            }

            { page ?
                <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  color="primary"
                />
                : ""
              }
            </ul>
          
          <div className={style.detail}>
            {selectedJob? (
              <div className={style.jobDetailArea}>
                <Link href={selectedJob.link} underline="hover" sx={{color:"black"}}>
                  <Typography sx={{ fontFamily:'Exo', fontWeight: 'bold', fontSize: 32}} component="h1">
                    {selectedJob.title.length > 50 ? selectedJob.title.slice(0,50) + "...": selectedJob.title}
                  </Typography>
                </Link>

                <Typography
                 component="h1"
                 variant="subtitle1"
                 align="left"
                 sx={{ fontFamily:'Exo'}}
                  color="text.secondary"
                  gutterBottom
                >
                  {selectedJob.company}
                </Typography>
                <Button sx={{mt:2, textTransform:"none"}} variant="contained" href={selectedJob.link}>Apply in JobsDB</Button>
                <Typography sx={{mt:5, fontSize: 16, whiteSpace:"pre-line" }} gutterBottom>
                  {selectedJob.requirement}
                </Typography>
              </div>
            ) :
              <>
            
              </>
           
            
            }
          </div>
        </div>
      </div>
    </div>
  );
};
