const source = process.env.REACT_APP_API_SERVER

// export async function GetJobListByCategory(category:string, cb:Function){
export async function GenerateCoverLetter(coverLetterSubmitted:any){
    console.log("coverLetterData for generate cover letter in API", coverLetterSubmitted)
  const res = await fetch(`${source}/generateCoverLetterDocx`, {
    method:"POST",
    headers: {
      "Content-Type":"application/json"
    },
    body: JSON.stringify(coverLetterSubmitted)
  })
  const resp = await res.json()
  // return cb(resp)
  return resp
}