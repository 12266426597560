import { useQuery } from "@tanstack/react-query";

const source = process.env.REACT_APP_API_SERVER;

export function useLoadResume(user_id: number | null) {
  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["resumeList"],
    queryFn: async () => {
      const res = await fetch(`${source}/dashboard/loadResume/${user_id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      const resp = await res.json();
      // console.log("resp: ",resp)
      return resp;
    },
  });

  if (isLoading || isFetching) return "Data is coming";

  if (error) {
    console.log("error: ", error);
    return "Error occurred";
  }

  if (!data) {
    return [];
  }

  // console.log("data: ",data)
  return data;
}

export function useLoadCoverLetter(user_id: number | null) {
  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["coverLetterList"],
    queryFn: async () => {
      const res = await fetch(
        `${source}/dashboard/loadCoverLetter/${user_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const resp = await res.json();
      return resp;
    },
  });

  if (isLoading || isFetching) return "Data is coming";

  if (error) {
    return "Error occurred";
  }

  if (!data) {
    return [];
  }

  return data;
}

export async function deleteResume(resumeId: any) {
  console.log("deleteResume in API", resumeId);
  const res = await fetch(`${source}/dashboard/deleteResume`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      resumeId,
    }),
  });

  const resp = await res.json();

  return resp;
}

export async function deleteCoverLetter(coverLetterId: any) {
  console.log("deleteCoverLetter in API", coverLetterId);
  const res = await fetch(`${source}/dashboard/deleteCoverLetter`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    body: JSON.stringify({
      coverLetterId,
    }),
  });

  const resp = await res.json();

  return resp;
}
