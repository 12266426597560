const source = process.env.REACT_APP_API_SERVER

// export async function GetJobListByCategory(category:string, cb:Function){
export async function GetJobListByCategory(category:string){
  const res = await fetch(`${source}/jobList/${category}`, {
    method:"GET",
    headers: {
      "Content-Type":"application/json"
    },
  })
  const resp = await res.json()
  // return cb(resp)
  return resp
}


// export function getJobListByCategory(category: string) {
//     const { isLoading, error, data, isFetching } = useQuery({
//       queryKey: ["jobList"],
//       queryFn: async () => {
//         const res = await fetch(`${source}/getJobList`, {
//           headers: {
//             // Authorization: `Bearer ${localStorage.getItem("token")}`,
//           },
//         });
  
//         const resp = await res.json();
//         return resp.data;
//       },
//     });
  
//     if (isLoading || isFetching) return "Data is coming";
  
//     if (error) {
//       return "Error occurred";
//     }
  
//     if (!data) {
//       return [];
//     }
  
//     return data;
//   }