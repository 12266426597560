
import style from "./JobSearch.module.css";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useLocation } from "react-router-dom";
import { ButtonBase } from "@mui/material";
import Chip from '@mui/material/Chip';

export type JobListProps = {
        id: number
        category:string
        title:string
        requirement:string
        company:string
        paramsJobId:string|null
        link:string
        getJobDetail:()=>void
}

function JobListItem (props:JobListProps) {

    console.log("JobListItem props params: ", props.paramsJobId)

    const location = useLocation();

    const getJobDetailIdHandler = () => {
        props.getJobDetail()
    }

    return (
        <ButtonBase color="inherit" 
        className={
            style.jobList +
            " " +
            (props.paramsJobId == props.id.toString()
              ? style.activeJobItem
              : "")
          }
        type="submit" 
        onClick={getJobDetailIdHandler}
        >
            <Typography
                  component="h1"
                  variant="h6"
                  align="left"
                  sx={{ fontFamily:'Exo', fontWeight: 'bold'}}
              >
                  {props.title.length > 50 ? props.title.slice(0,50) + "..." : props.title }
              </Typography>
              <Typography
                  component="h1"
                  variant="subtitle2"
                  align="left"
                  sx={{ fontFamily:'Exo',}}
                  color="text.secondary"
              >
                  {props.company}
              </Typography>
            {/* <div>Job ID:{props.id} </div> */}
            <Chip label={props.category.split('_').join(' ')}/>
        </ButtonBase>
    )
}

export default JobListItem

